import { render, staticRenderFns } from "./ItserveDialog.vue?vue&type=template&id=af9e064c&scoped=true&"
import script from "./ItserveDialog.vue?vue&type=script&lang=js&"
export * from "./ItserveDialog.vue?vue&type=script&lang=js&"
import style0 from "./ItserveDialog.vue?vue&type=style&index=0&id=af9e064c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af9e064c",
  null
  
)

export default component.exports