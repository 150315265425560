<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="itserve-center">

      <template v-if="lastIndicators !== null">
        <send-indications-info-part />
        <div
          class="send-indications-content mt-10 flex-display-fix"
        >
          <div class="place-content-center">
            <flashlight />
            <h5 class="count-zones apply-loader">
              {{
                $t("regionalEnergy.sendIndications.zone" + lastIndicators.nzone)
              }}
            </h5>
            <!-- <van-col span="24" class="mt-25">
              <van-switch
                active-color="#4CD964"
                inactive-color="#777777"
                v-model="photoMode"
              />
            </van-col> -->
            <template v-if="lastIndicators.nzone === 1">
              <template v-if="windowWidth >= 340">
                <single-zone-standart />
              </template>
              <template v-else>
                <single-zone-small />
              </template>
            </template>
            <template v-else>
              <template v-if="windowWidth >= 340">
                <multi-zone-standart />
              </template>
              <template v-else>
                <multi-zone-small />
              </template>
            </template>
          </div>
        </div>
      </template>
    </van-col>
  </van-row>
</template>

<script>
import "vue-awesome/icons/history";
import "vue-awesome/icons/paper-plane";
// import Form from "vant/lib/form/index";
import SingleZoneStandart from "@/components/indications/SingleZoneStandart";
import MultiZoneStandart from "@/components/indications/MultiZoneStandart";
import SendIndicationsInfoPart from "@/components/indications/SendIndicationsInfoPart";
import Flashlight from "@/components/indications/Flashlight";
import SingleZoneSmall from "@/components/indications/SingleZoneSmall";
import MultiZoneSmall from "@/components/indications/MultiZoneSmall";
// import ItserveInput from "@/components/forms/ItserveInput";
// import InfoMessage from "@/components/content/InfoMessage";
//import Switch  from "vant/lib/switch/index";

export default {
  name: "SendIndications",
  components: {
    // VanSwitch: Switch,
    MultiZoneSmall,
    SingleZoneSmall,
    Flashlight,
    SendIndicationsInfoPart,
    MultiZoneStandart,
    SingleZoneStandart,
    // VanForm: Form,
    // ItserveInput,
    // InfoMessage,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    photoMode: {
      get() {
        return this.$store.state.RegionalEnergy.sendPhoto;
      },
      set(photoMode) {
        this.$store.commit("RegionalEnergy/SET_PHOTO_MODE", photoMode);
      },
    },
    newPhone: {
      get() {
        return this.$store.state.Settings.newIndicationPhone;
      },
      set(newPhone) {
        this.$store.commit("Settings/UPDATE_INDICATION_PHONE", newPhone);
        this.dialogMessage = this.$t("settings.successMessage");
      },
    },
    indicationPhone() {
      return this.$store.state.Settings.indicationPhone.phone;
    },
    lastIndicators() {
      return this.$store.state.RegionalEnergy.lastIndicators;
    },
    errorMessage() {
      return this.$store.state.Api.errors.message;
    },
  },
  created() {
    this.checkUidPhone();
    this.$store.dispatch(
      "RegionalEnergy/getLastIndications",
      this.$route.params.account
    );
    window.addEventListener("resize", this.changeWidthOfWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.changeWidthOfWindow);
  },
  methods: {
    switchMode() {
      this.$store.commit("RegionalEnergy/SET_PHOTO_MODE");
    },
    changeWidthOfWindow() {
      this.windowWidth = window.innerWidth;
    },
    checkUidPhone() {
      this.$store.dispatch("Settings/getIndicationPhone", this.$store.state.Auth.nativeData.uid);
    },
  },
};
</script>
<style scoped>
@import "../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../node_modules/vant/lib/overlay/index.css";
@import "../../../../node_modules/vant/lib/popup/index.css";
@import "../../../../node_modules/vant/lib/switch/index.css";

.count-zones {
  display: inline-block;
  border: 1px solid #c4c4c4;
  padding: 5px 28px 5px 28px;
  border-radius: 5px;
}
h5 {
  color: #0b0b0b;
  padding: 0;
  text-align: center;
  font-size: 18px;
  margin: 0;
}
.app-theme-dark .payment-box {
  background-color: #1d1d1d;
}
.app-theme-dark .white-color {
  color: #fff;
}
.pt15 {
  padding-top: 15px;
}
.center {
  text-align: center;
}
.main-page-account {
  padding-bottom: 45px;
}
</style>
