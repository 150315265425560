var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-row',{staticClass:"page-wrapper payment-page",attrs:{"justify":"center"}},[[_c('van-col',{staticClass:"itserve-center bottom-content-grow flex-display-fix",attrs:{"span":"24"}},[_c('form',{attrs:{"method":"post","action":"https://www.portmone.com.ua/gateway/"},on:{"submit":_vm.runPayment}},[_c('div',{staticClass:"itserve-center"},[_c('input',{attrs:{"type":"hidden","name":"bodyRequest"},domProps:{"value":JSON.stringify({
                 "paymentTypes": {
                      "card": "Y",
                      "gpay": _vm.googlePay,
                      "applepay": _vm.applePay
                  },
                  "priorityPaymentTypes": {
                      "card": "1",
                      "gpay": "2",
                      "applepay" :"0"
                  },
                  "payee": {
                   "payeeId": "23364",
                  },
                  "payer": {
                      "emailAddress": "",
                      "lang": this.$store.state.Auth.nativeData.platform
                  },
                  "order": {
                    "description": this.$store.state.Auth.account.accountNumber,
                    "shopOrderNumber": this.$store.state.Payment.order_id,
                    "successUrl": "https://kharkiv2.billing.city/api/enterprises/v1/payment/portmone/success-payment/" + this.$store.state.Payment.order_id,
                    "failureUrl": "https://kharkiv2.billing.city/api/enterprises/v1/payment/portmone/failed-payment",
                    "billAmount": this.$store.state.Payment.paymentData.amount,
                    "billCurrency": "UAH",
                    "attribute1": this.$store.state.Auth.account.accountNumber
                },
                  "style": {
                      "type": "light",
                      "bcMain": "#fff",
                      "backgroundColorButtons": "#0f51a6",
                      "colorTextAndIcons": "#0f51a6"
                  },
})}}),_c('input',{attrs:{"type":"hidden","name":"typeRequest","value":"json"}}),_c('itserve-button',{staticClass:"mt-15 apply-loader",attrs:{"type":"submit","value":_vm.$t('pay.pay') + ' ' +  this.$store.state.Payment.paymentData.amount + ' ' +  _vm.$t('currency.uah')}})],1)]),_c('simple-dialog',{attrs:{"success":true,"title":this.$t('pay.fees')},on:{"change":_vm.runPayment},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }