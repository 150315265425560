<template>
  <div>
  <van-row class="white-page-bg payment-cb">
    <van-col span="24">
      <ul class="account-data-list">
        <li v-if="paymentResponse" v-bind:key="paymentResponse.accountNumber">
          <ul class="service-item" v-if="paymentResponse.checked === true">
            <van-divider>
              ПрАТ Харківенергозбут (населення, за е/енергію) ЄК
            </van-divider>
            <van-row class="mb-5">
              <van-col span="14" class="text-left">
                Електропостачання
              </van-col>
              <van-col span="10" v-if="paymentResponse.status === 0" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                <div :key="paymentResponse.status">
                  {{ $t("paymentHistorySearch.failed") }}
                </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="paymentResponse.status === 1" class="apply-loader payment-success">
                <transition name="slide-fade" mode="out-in">
                  <div :key="paymentResponse.status">
                {{ $t("paymentHistorySearch.success") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="paymentResponse.status === 2" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="paymentResponse.status">
                {{ $t("paymentHistorySearch.declined") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="paymentResponse.status === 3" class="apply-loader payment-pending">
                <transition name="slide-fade" mode="out-in">
                  <div :key="paymentResponse.status">
                {{ $t("paymentHistorySearch.pending") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="paymentResponse.status === 4" class="apply-loader payment-pending">
                <transition name="slide-fade" mode="out-in">
                  <div :key="paymentResponse.status">
                {{ $t("paymentHistorySearch.pending") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="paymentResponse.status === 5" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="paymentResponse.status">
                    {{ $t("paymentHistorySearch.bankCard") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="paymentResponse.status === 6" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="paymentResponse.status">
                    {{ $t("paymentHistorySearch.cardDeclined") }}
                  </div>
                </transition>
              </van-col>
            </van-row>
          </ul>

        </li>
        <li v-for="(balance, index) in balances" v-bind:key="index">
          <ul class="service-item">
            <van-divider>
              <!--            <fire-icon fillColor="#fbc02d"/>-->
              {{ balance.company_name }}
            </van-divider>
            <van-row class="mb-5">
              <van-col span="14" class="text-left">
                {{ balance.service_name }}
              </van-col>
              <van-col span="10" v-if="balance.status === 0" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                {{ $t("paymentHistorySearch.failed") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="balance.status === 1" class="apply-loader payment-success">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                {{ $t("paymentHistorySearch.success") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="balance.status === 2" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                {{ $t("paymentHistorySearch.declined") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="balance.status === 3" class="apply-loader payment-pending">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                {{ $t("paymentHistorySearch.pending") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="balance.status === 4" class="apply-loader payment-pending">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                {{ $t("paymentHistorySearch.pending") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="balance.status === 5" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                    {{ $t("paymentHistorySearch.bankCard") }}
                  </div>
                </transition>
              </van-col>
              <van-col span="10" v-else-if="balance.status === 6" class="apply-loader payment-declined">
                <transition name="slide-fade" mode="out-in">
                  <div :key="balance.status">
                    {{ $t("paymentHistorySearch.cardDeclined") }}
                  </div>
                </transition>
              </van-col>
            </van-row>
          </ul>

        </li>
      </ul>

    </van-col>
  </van-row>
  <van-row class="payment-box pt15">
    <div @click="runRate" class="white-color center mt-15 mb-15">{{ $t("settings.rate_now")}}</div>
    <van-col span="4"></van-col>
    <van-col span="16">
      <van-button
          @click="runRate"
          round
          size="large"
          class="mt-15"
          type="info">
        {{ $t("settings.rate_now_button")}}
      </van-button>
    </van-col>
    <van-col span="4"></van-col>
  </van-row>
  </div>
</template>

<script>
import VanDivider from "vant/lib/divider/index";
import VanButton from "vant/lib/button/index";

import io from "@/socket";
export default {
  components: {
    VanDivider,
    VanButton
  },
  name: "BalancesSuccess",
  data() {
    return {
      socketObject: null
    };
  },
  computed: {
    paymentResponse: {
      get() {
        return this.$store.state.Payment.paymentData;
      },
      set(status) {
        this.$store.commit("Payment/SET_STATUS", status);
      }
    },
    balances: {
      get() {
        return this.$store.state.Payment.paymentData.balances.filter(n => n);
      },
      set(balances) {
        this.$store.commit("Payment/SET_BALANCES", balances);
      }
    },
    ls () {
      return this.$store.state.Auth.account.accountNumber;
    }
  },
  created() {
    let harenergoBalances;
    console.log(JSON.stringify(this.$store.state.Payment.paymentData))
    this.socketObject = io.connect();
    console.log(this.socketObject.connected);
    this.$store.dispatch("Payment/getPaymentBalancesPage");
    if(this.$store.state.Payment.paymentData.checked === true && this.$store.state.Payment.paymentData.amount > 0){
      this.$store.dispatch("Payment/getPaymentPage");
    }
    this.$store.commit("Api/hideLoader");
    this.socketObject.on('userCount', function (data) {
      console.log(JSON.stringify(data));
    });
    this.socketObject.on("statusUpdate", function(data){
      harenergoBalances = this.balances;
      console.log("STATUS CHANGE FIRE!!!!!!!");
      console.log(parseInt(data.ls) === this.ls)
      if(data.type === 'balances'){
        for(let i = 0; i < harenergoBalances.length; i++){
          if(harenergoBalances[i].service_id === data.service_id){
            console.log(harenergoBalances[i].service_account + " status changed")
            harenergoBalances[i].status = data.status;
          }
        }
        this.balances = harenergoBalances;
      }
      else if(data.type === 'electricity' && parseInt(data.ls) === this.ls){
        console.log(this.ls + " status changed")
        this.paymentResponse.status = data.status;
      }
    }.bind(this));

  },
  beforeRouteLeave(to, from, next) {
    this.paymentResponse.status = 4;
    this.socketObject.disconnect();
    next();
  },
  methods: {
    async getPaymentStatus() {
      await this.$store.dispatch("Payment/getPaymentStatus", this.paymentResponse.status_id)
          .then((res) => {
            console.log(JSON.stringify(res));
          });
    },
    runRate(){
      this.socketObject.disconnect();
      this.nativeSendEvent("url", "https://cutt.ly/NwEFP7CC");
    }
  }
};
</script>

<style scoped>
.payment-declined {
  font-size: 14px;
  color: darkred;
  text-align: right!important;
  margin-right: 0;
}
.app-theme-dark .payment-declined{
  color: red;
}
.payment-success {
  font-size: 14px;
  color: darkgreen;
  text-align: right!important;
  margin-right: 0;
}
.app-theme-dark .payment-success{
  color: green;
}
.payment-pending {
  font-size: 14px;
  color: darkgoldenrod;
  text-align: right!important;
  margin-right: 0;
}
.app-theme-dark .payment-pending{
  color: yellow;
}
.app-theme-dark .account-data-list .service-item {
  margin: 10px 10px 10px 10px;
  background-color: #474545;
  border-radius: 10px;
  padding: 5px;
}

.account-data-list .service-item {
  margin: 10px 10px 10px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px;
}
.app-theme-dark .white-color {
  color: #fff;
}
.center {
  text-align: center;
}
.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}
.app-theme-dark .payment-box {
  background-color: #1d1d1d;
}

.home-account-shadow {
  border-radius: 0 0 15px 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
}
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
