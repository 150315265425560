<template>
  <footer>
    <template v-if="supportRoutes.indexOf($route.name) >= 0">
      <support-bottom-menu />
    </template>
    <template v-if="$route.name !== 'PayAll'">
      <template
        v-if="
          currentTime >= 9 &&
          currentTime < 17 &&
          (dayNumber !== 0 && dayNumber !== 6)
        "
      >
        <div @click="openChat" class="support-helper-btn">
          <template v-if="supportsOnline">
            <span class="support-text">
              <!-- {{ $t("components.footer.techSupport") }} -->
            </span>
          </template>
        </div>
      </template>
      <template v-else>
        <template v-if="supportRoutes.indexOf($route.name) < 0">
          <div @click="showSupport = true" class="support-helper-btn">
          <span class="support-text">
              {{ $t("components.footer.techSupport") }}
            </span>
          </div>
            <div class="icon-box apply-loader" @click="showSupport = true">
              <chat-icon fill-color="black" size="40"/>
            </div>
          <dialogFlow v-if="showSupport" @close="showSupport = false"></dialogFlow>
        </template>
      </template>
    </template>
    <template v-else>
    </template>
  </footer>
</template>
<script>
import SupportBottomMenu from "@/components/support/SupportBottomMenu";
import appBridge from "@/mixins/app-bridge";
import Vue from "vue";
import store from "@/store";
import Tawk from "@/plugins/tawk";
import ChatIcon from "vue-material-design-icons/Chat";
import DialogFlow from "../dialogFlow/dialogFlow";
//import VanButton from "vant/lib/button/index";

export default {
  mixins: [appBridge],
  name: "AppFooter",
  data() {
    return {
      showSupport: false,
      currentTime: 0,
      supportsOnline: true,
      dayNumber: 0,
      supportRoutes: ["Contacts", "WriteTicket", "SupportPage"],
    };
  },
  components: {
    DialogFlow,
    SupportBottomMenu,
    ChatIcon,
    //VanButton
  },
  created() {
    this.calcTime("+3");
    if (
      this.currentTime >= 9 &&
      this.currentTime < 17 &&
      (this.dayNumber !== 0 && this.dayNumber !== 6)
    ) {
      if(this.$router.name !== 'PayAll') {
        Vue.use(Tawk, {
          tawkSrc: "https://embed.tawk.to/61558c87d326717cb6840d29/1fgr2psh5",
        });
        window.Tawk_API.onLoad = () => {
          store.dispatch("Auth/setUserPhone");
          if (window.Tawk_API.getStatus() === "offline") {
            this.supportsOnline = false;
          }
        };
      }
    }
  },
  methods: {
    calcTime(offset) {
      let d = new Date();
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      let nd = new Date(utc + 3600000 * offset);
      this.currentTime = nd.getHours();
      this.dayNumber = nd.getDay();
    },
    async openChat() {
      try {
        await store.dispatch("Auth/setUserPhone");
        window.Tawk_API.maximize();
      } catch (e) {
        this.nativeSendEvent("tawkSupport");
      }
    },
    openDialogFlow() {
      alert("chlenix")
    },
    runPayment() {

      const message = this.$refs.inputField.value;
      alert(message);
    }

  },
};
</script>
<style scoped>
footer {
  background-color: #fff;
}
.support-helper-btn {
  position: relative;
  height: 75px;
  display: inline-block;
  line-height: 75px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  border-radius: 5px;
}
.arrow-right-icon {
  margin-top: 5px;
}
.support-text {
  color: #1d1d1d;
}
.support-off {
  padding-top: 10px;
  padding-bottom: 10px;
}
.icon-box {
  background-color: #fbc02d;
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  line-height: 85px;
  position: fixed;
  right: 4%;
  bottom: 2%;
}

.payment-box {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
