<template>
  <div v-if="account">

    <div>
      <ul class="account-data-list" :key="trigger">
<!--        <li class="home-account-shadow" v-bind:key="har_account.accountNumber">-->
<!--          <ul class="service-item">-->
<!--            <van-divider>-->
<!--              &lt;!&ndash;            <fire-icon fillColor="#fbc02d"/>&ndash;&gt;-->
<!--              Електропостачання-->
<!--            </van-divider>-->
<!--            <ul class="mb-5">-->
<!--              <li class="ml-15">-->
<!--                <van-checkbox v-model="har_checked" @change="calculateTotalCheckboxHarenergo(har_account)">-->
<!--                  ПрАТ Харківенергозбут (населення, за е/енергію) ЄК-->
<!--                </van-checkbox>-->
<!--              </li>-->
<!--              <li>-->
<!--                <van-row>-->
<!--                  <van-col span="12">-->
<!--                    <p class="left">{{ $t("components.accounts.ls") }}: {{ har_account.ls }}</p>-->
<!--                  </van-col>-->
<!--                  <van-col span="12">-->
<!--                    <template v-if="har_account.balanceTitle < 0">-->
<!--                      <p class="right">{{ $t("components.accounts.overpay") }}: <span-->
<!--                          :class="-->
<!--                  har_account.balanceTitle === 0-->
<!--                    ? ''-->
<!--                    : har_account.balanceTitle > 0-->
<!--                    ? 'positive-balance'-->
<!--                    : 'negative-balance'-->
<!--                "-->
<!--                      ><b>{{ Math.abs(har_account.balanceTitle) }}</b>-->
<!--              </span> {{ $t("components.accounts.currency") }}</p>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      <p class="right">{{ $t("components.accounts.debt") }}: <span-->
<!--                          :class="-->
<!--                  har_account.balanceTitle === 0-->
<!--                    ? ''-->
<!--                    : har_account.balanceTitle > 0-->
<!--                    ? 'positive-balance'-->
<!--                    : 'negative-balance'-->
<!--                "-->
<!--                      ><b>{{ Math.abs(har_account.balanceTitle) }}</b>-->
<!--              </span> {{ $t("components.accounts.currency") }}</p>-->
<!--                    </template>-->
<!--                  </van-col>-->
<!--                </van-row>-->
<!--              </li>-->

<!--              <li>-->
<!--                <van-field-->
<!--                    v-model="har_account.balance_temp"-->
<!--                    :ref="har_account.accountNumber"-->
<!--                    @change="calculateTotalInputHarenergo"-->
<!--                    class="input-border"-->
<!--                    input-align="center" type="number" center clearable :border="true" placeholder="0">-->
<!--                  <template #button>-->
<!--                    {{ $t("currency.uah") }}-->
<!--                  </template>-->
<!--                </van-field>-->
<!--              </li>-->
<!--              <li>-->
<!--                <p class="right">-->
<!--                  {{ $t("components.accounts.feeWillBeNew") }} 3-->
<!--                  {{ $t("components.accounts.currency") }}-->
<!--                </p>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </ul>-->

<!--        </li>-->
        <li class="home-account-shadow" v-for="balance in balances" v-bind:key="balance.id">
          <ul class="service-item">
            <van-divider>
              <!--            <fire-icon fillColor="#fbc02d"/>-->
              {{ balance.service_name }}
            </van-divider>
            <ul class="mb-5">
              <li class="ml-15">
                <van-checkbox v-model="balance.checked" @change="calculateTotalCheckbox(balance)"
                              >
                  {{ balance.company_name }}
                </van-checkbox>
              </li>
              <li>
                <van-row>
                  <van-col span="12">
                    <p class="left">{{ $t("components.accounts.ls") }}: {{ balance.service_account }}</p>
                  </van-col>
                  <van-col span="12">
                    <template v-if="balance.balanceTitle < 0">
                      <p class="right">{{ $t("components.accounts.overpay") }}: <span
                          :class="
                  balance.balanceTitle === 0
                    ? ''
                    : balance.balanceTitle > 0
                    ? 'positive-balance'
                    : 'negative-balance'
                "
                      ><b>{{ Math.abs(balance.balanceTitle) }}</b>
              </span> {{ $t("components.accounts.currency") }}</p>
                    </template>
                    <template v-else>
                      <p class="right">{{ $t("components.accounts.debt") }}: <span
                          :class="
                  balance.balanceTitle === 0
                    ? ''
                    : balance.balanceTitle > 0
                    ? 'positive-balance'
                    : 'negative-balance'
                "
                      ><b>{{ Math.abs(balance.balanceTitle) }}</b>
              </span> {{ $t("components.accounts.currency") }}</p>
                    </template>
                  </van-col>
                </van-row>

              </li>

              <li>
                <van-field
                    v-model="balance.balance"
                    :ref="balance.id"
                    @change="calculateTotalInput"
                    class="input-border"
                    input-align="center" type="number" center clearable placeholder="0">
                  <template #button>
                    {{ $t("currency.uah") }}
                  </template>
                </van-field>
              </li>

            </ul>
          </ul>

        </li>
      </ul>
    </div>
    <div>
      <van-row class="payment-box pt15 mb-10">
        <van-col span="4"></van-col>
        <van-col span="16">
          <van-button
              @click="runPayment"
              :disabled="amount <= 0"
              style="margin-bottom: 10px; border-radius: 12px"
              round
              icon="card"
              size="large"
              type="info">
            {{ $t('pay.pay') }} {{ amount }} {{ $t("currency.uah") }}
          </van-button>
        </van-col>
        <van-col span="4"></van-col>
      </van-row>
    </div>
  </div>

</template>
<script>
import VanCheckbox from "vant/lib/checkbox/index";
import VanDivider from "vant/lib/divider/index";
import VanField from "vant/lib/field/index";
import Dialog from "vant/lib/dialog/index";
// import LightningBoltIcon from "vue-material-design-icons/LightningBolt.vue";
// import FireIcon from "vue-material-design-icons/Fire.vue";
// import WaterIcon from "vue-material-design-icons/Water.vue";
// import WaterBoilerIcon from "vue-material-design-icons/WaterBoiler.vue";
import VanButton from "vant/lib/button/index";

export default {
  name: "Services",
  components: {
    VanDivider,
    VanCheckbox,
    VanField,
    // LightningBoltIcon,
    // FireIcon,
    // WaterIcon,
    // WaterBoilerIcon,
    VanButton,
  },
  created() {
    this.preparePaymentDataOnLoad();
  },
  props: {
    account: Object,
    total: String,
    balances: Array,
    type: {
      type: String,
      default: "regular",
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cards() {
      return this.$store.state.Payment.cards;
    },
    status() {
      console.log("USER STATUS: " + this.$store.state.Auth.status);
      return this.$store.state.Auth.status;
    }
  },
  data() {
    return {
      //showDialog: true,
      amount: this.$store.state.Auth.total,
      selectedBalance: this.balances,
      trigger: 0,
      // har_account: this.account,
      // har_checked: true,
      MySQLEvents: null
    };
  },
  methods: {

    calculateTotalCheckbox(balance) {
      this.amount = parseFloat(this.amount);
      balance.balance = parseFloat(balance.balance);
      if (balance.checked === true) {
        if (balance.balance > 0) {
          this.amount += balance.balance
        }
      } else {
        if (balance.balance > 0) {
          this.amount -= balance.balance
        }
      }
      this.amount = this.amount.toFixed(2);

      this.preparePaymentData();
    },

    // calculateTotalCheckboxHarenergo(balance) {
    //   this.amount = parseFloat(this.amount);
    //   balance.balance_temp = parseFloat(balance.balance_temp);
    //   if (this.har_checked === true) {
    //     if (balance.balance_temp > 0) {
    //       this.amount += balance.balance_temp
    //     }
    //   } else {
    //     if (balance.balance_temp > 0) {
    //       this.amount -= balance.balance_temp
    //     }
    //   }
    //   this.amount = this.amount.toFixed(2);
    //   if (this.trigger === 0) {
    //     this.trigger += 1;
    //   }
    // },

    calculateTotalInput() {
      let totalAmount = 0;
      if (this.balances) {
        for (let i = 0; i < this.balances.length; i++) {
          // if(this.balances[i].balance > 0 && this.status === 1){
          //   this.balances[i].checked = true
          // }
          // if (this.balances[i].checked === true && this.balances[i].balance > 0 && this.status === 1) {
          if (this.balances[i].checked === true && this.balances[i].balance > 0) {
            totalAmount += parseFloat(this.balances[i].balance)
          }
        }
      }

      // if (this.har_checked === true && this.har_account.balance_temp > 0) {
      //   totalAmount += parseFloat(this.har_account.balance_temp)
      // }

      this.amount = totalAmount.toFixed(2);
    },

    // calculateTotalInputHarenergo() {
    //   let totalAmount = 0;
    //   if (this.balances) {
    //     for (let i = 0; i < this.balances.length; i++) {
    //       // if(this.balances[i].balance > 0 && this.status === 1){
    //       //   this.balances[i].checked = true
    //       // }
    //       if (this.balances[i].checked === true && this.balances[i].balance > 0 && this.status === 1) {
    //         totalAmount += parseFloat(this.balances[i].balance)
    //       }
    //     }
    //   }
    //
    //   if (this.har_account.balance_temp > 0 && this.har_checked === false) {
    //     this.har_checked = true;
    //     totalAmount -= parseFloat(this.har_account.balance_temp)
    //   }
    //
    //   if (this.har_checked === true && this.har_account.balance_temp > 0) {
    //     totalAmount += parseFloat(this.har_account.balance_temp)
    //   }
    //
    //   this.amount = totalAmount.toFixed(2);
    // },

    preparePaymentData() {
      this.selectedBalance = [];
      if (this.balances !== null) {
        for (let i = 0; i < this.balances.length; i++) {

          if (this.balances[i].checked === true && this.balances[i].balance > 0) {
            this.selectedBalance[i] = this.balances[i];
            this.selectedBalance[i].status = 4;
          }
        }
      }
      if (this.trigger === 0) {
        this.trigger += 1;
      }
    },

    preparePaymentDataOnLoad() {
      this.selectedBalance = [];
      this.amount = 0;
      // this.har_account.balanceTitle = this.account.balance;
      // if (this.har_account.balance < 0) {
      //   this.har_account.balance_temp = 0
      // } else {
      //   this.har_account.balance_temp = this.har_account.balance
      // }
      // this.amount = parseFloat(this.har_account.balance_temp);
      // if (this.status === 1) {
        if (this.balances !== null) {
          for (let i = 0; i < this.balances.length; i++) {

            this.balances[i].balanceTitle = this.balances[i].balance;
            if (this.balances[i].balance < 0 || this.balances[i].balance === 0) {
              // this.balances[i].checked = true;
              this.balances[i].balance = 0;
            }
            if (this.balances[i].checked === true && this.balances[i].balance > 0) {
              this.selectedBalance[i] = this.balances[i];
              this.selectedBalance[i].status = 4;
            }
            this.amount += parseFloat(this.balances[i].balance);
          }
        }
      // } else {
      //   for (let i = 0; i < this.balances.length; i++) {
      //     this.balances[i].balanceTitle = this.balances[i].balance;
      //     this.balances[i].checked = false;
      //     if (this.balances[i].balance < 0) {
      //       this.balances[i].balance = 0;
      //     }
      //   }
      // }
      this.amount = this.amount.toFixed(2)
      if (this.trigger === 0) {
        this.trigger += 1;
      }
    },

    showMessage() {
      console.log("MESSAGE FIRE")
      if (this.status === 0) {
        Dialog.confirm({
          title: this.$t("paymentCards.dialogPayMessage"),
          message: this.$t("pay.statusMessage"),
          cancelButtonText: this.$t("paymentCards.dialogCancel"),
          cancelButtonColor: "grey!important",
          confirmButtonText: this.$t("stripe.subscribeButton"),
          confirmButtonColor: "blue!important",
        })
            .then(async () => {
              await this.$router.push({
                name: "StripePayment"
              });

            })
            .catch(() => {
            });
      }
    },

    async runPayment() {
      let status_id = function () {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
      }
      this.preparePaymentData();
      // if (this.status === 1) {
      //   if (this.cards.length > 0) {
          this.$store.commit("Auth/SET_TOTAL", this.amount);
          if(this.amount < 50){
            Dialog.alert({
              message: 'Мінімальна сума до сплати 50 грн.',
              confirmButtonText: "Ok",
            });
          }
          else {
            // if (this.har_account.balance_temp > 0) {
            //   this.$store.commit("Payment/SET_PAYMENT_CHECKED", this.har_checked);
            // }
            this.$store.commit("Payment/SET_BALANCES", this.selectedBalance);
            this.$store.commit("Payment/SET_STATUS_ID", status_id());
            let dialogTitle = "<table>";
            // if (this.har_checked == true && this.har_account.balance_temp > 0) {
            //   dialogTitle += "<tr>";
            //   dialogTitle += "<td class='ml-0' width='65%'><p>Електропостачання: </p></td><td class='right'><p>" + this.har_account.balance_temp + this.$t("currency.uah") + "</p></td>";
            //   dialogTitle += "</tr>"
            // }
            for (let i = 0; i < this.balances.length; i++) {
              if (this.balances[i].checked === true && this.balances[i].balance > 0) {
                dialogTitle += "<tr>";
                dialogTitle += "<td class='ml-0' width='65%'><p>" + this.balances[i].service_name + ": </p></td><td class='right'><p>" + this.balances[i].balance + " " + this.$t("currency.uah") + "</p></td>";
                dialogTitle += "</tr>"
              }
            }

            dialogTitle += "</table>";
            Dialog.confirm({
              title: this.$t("paymentCards.dialogPayMessage"),
              message: dialogTitle,
              cancelButtonText: this.$t("paymentCards.dialogCancel"),
              cancelButtonColor: "grey!important",
              confirmButtonText: this.$t("pay.pay") + " " + this.amount + " " + this.$t("currency.uah"),
              confirmButtonColor: "blue!important",
            })
                .then(async () => {
                  // await this.$router.push({ name: "BalancesPaid" });
                  // if (this.har_checked === true) {
                  //   this.har_account.balance = this.har_account.balance_temp
                  // }
                  // this.$store.commit("Payment/UPDATE_AMOUNT", this.har_account.balance_temp);
                  await this.$router.push({
                    name: "StripePaymentPage",
                    params: this.$route.params,
                  });
                })
                .catch(() => {
                });
            // } else {
            //   this.$store.commit("Auth/SET_TOTAL", this.amount);
            //   if (this.har_account.balance_temp > 0) {
            //     this.$store.commit("Payment/SET_PAYMENT_CHECKED", this.har_checked);
            //   }
            //   this.$store.commit("Payment/SET_BALANCES", this.selectedBalance);
            //   this.$store.commit("Payment/SET_STATUS_ID", status_id());
            //   Dialog.confirm({
            //     title: this.$t("pay.addCard"),
            //     message: this.$t("paymentPage.addCard"),
            //     cancelButtonText: this.$t("paymentCards.dialogCancel"),
            //     cancelButtonColor: "grey!important",
            //     confirmButtonText: this.$t("paymentCards.dialogConfirm"),
            //     confirmButtonColor: "blue!important",
            //   })
            //       .then(async () => {
            //         await this.$router.push({ name: "BalancesPaid" });
            //         if (this.har_checked === true) {
            //           this.har_account.balance = this.har_account.balance_temp
            //         }
            //         this.$store.commit("Payment/UPDATE_AMOUNT", this.har_account.balance_temp);
            //
            //         await this.$router.push({
            //           name: "PaymentBalancesCards",
            //           params: this.$route.params,
            //         });
            //       })
            //       .catch(() => {
            //       });
            // }
            // } else {
            //   if (this.har_checked === true) {
            //     this.har_account.balance = this.har_account.balance_temp
            //   }
            //   this.$store.commit("Payment/UPDATE_AMOUNT", this.har_account.balance_temp);
            //
            //   await this.$store.commit(
            //       "Payment/SET_USER_LS",
            //       this.$route.params.account
            //   );
            //   if (this.$store.state.Payment.paymentData.checked === true && this.$store.state.Payment.paymentData.amount > 0) {
            //     this.$store.dispatch("Payment/getPortmonePaymentPage");
            //   }
            //   await this.$router.push({
            //     name: "PortmonePayment",
            //     params: this.$route.params,
            //   });
            // }
          }

    },

  },
};
</script>

<style scoped>
.app-theme-dark .account-data-list .service-item {
  margin: 10px 10px 10px 10px;
  background-color: #474545;
  border-radius: 10px;
  padding: 5px;
}

.account-data-list .service-item {
  margin: 10px 10px 10px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px;
}

.app-theme-dark .white-color {
  color: #fff;
}

.center {
  text-align: center;
}

.right {
  text-align: right !important;
  margin-right: 15px;
}

.left {
  text-align: left !important;
  margin-left: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.app-theme-dark .payment-box {
  background-color: #1d1d1d;
  position: absolute;
  bottom: 0;
}

.payment-box {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
}

.home-account-shadow {
  border-radius: 0 0 15px 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
}

.input-border {
  border-bottom: 0.05em solid;
  /*border-radius: 10px;*/
}

.app-theme-dark .input-border {
  border: none;
}
</style>
