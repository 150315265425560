import api from "@/plugins/api/EntryPoint";

export default {
  async getPaymentHistory(params, callBackFn) {
    await api.post(`history/payments`, params, callBackFn);
  },
  async getPaymentHistoryNew(params, callBackFn) {
    await api.post(`history/payments-new`, params, callBackFn);
  },
};
