<template>
  <van-row class="white-page-bg payment-cb">
    <van-col span="24">
      <info-message class="itserve-center place-content-center">
        <img
          src="@/assets/images/action_canceled.png"
          alt="cancel-action"
        /><br />
        {{ $t("pay.cardNotAdded") }}
      </info-message>
    </van-col>
  </van-row>
</template>

<script>
import { Row, Col } from "vant";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  components: {
    "van-col": Col,
    "van-row": Row,
    InfoMessage,
  },
  name: "CancelCard",
};
</script>
<style scoped></style>
