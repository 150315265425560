<template>
  <van-row class="page-wraper payment-page">
    <loader :show="showLoader" />
    <template>
      <van-col class="round-shadow-btn mb-25" span="24">
        <info-message class="itserve-center place-content-center">{{
            $t("paymentPage.chooseCard")
          }}</info-message>
      </van-col>
      <van-col span="24">
        <template v-for="card in cards">
          <van-cell
              class="
                cards-list-cell
                shadow-white
                mb-25
                single-card
                place-content-center
              "
              :title="card.pan"
              :key="card.pan"
              value=""
              @click="chooseCard(card.card_token)"
          >
            <template #icon>
              <template v-if="card.pan.charAt(0) === '4'">
                <img src="@/assets/images/visa.png" class="card-type-image" />
              </template>
              <template v-else>
                <img
                    src="@/assets/images/mastercard.png"
                    class="card-type-image"
                />
              </template>
            </template>
          </van-cell>
        </template>
      </van-col>
      <van-col span="24" class="itserve-center">
        <itserve-route
            type="primary"
            :buttonName="$t('pay.addCard')"
            class="mt-15"
            route-name="AddBalanceCard"
        />
      </van-col>
    </template>
    <simple-dialog
        v-model="showDialog"
        :success="actionSuccessfully"
        :title="dialogMessage"
    />
  </van-row>
</template>

<script>
import Dialog from "vant/lib/dialog/index";
import Cell from "vant/lib/cell/index";
import InfoMessage from "@/components/content/InfoMessage";
import Loader from "@/components/content/Loader";
import ItserveRoute from "@/components/content/ItserveRoute";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  components: {
    ItserveRoute,
    Loader,
    InfoMessage,
    VanCell: Cell,
    SimpleDialog
  },
  name: "PaymentPage",
  computed: {
    showLoader() {
      return this.$store.state.Api.loading;
    },
    paymentData() {
      return this.$store.state.Payment.paymentData;
    },
    paymentResponse() {
      return this.$store.state.Payment.paymentResponse;
    },
    paymentUrl() {
      return this.$store.state.Payment.paymentUrl;
    },
    account() {
      return this.$store.state.Auth.account;
    },
    cards() {
      return this.$store.state.Payment.cards;
    },
  },
  data() {
    return {
      watchInterval: null,
      showDialog: false,
      dialogMessage: "",
      actionSuccessfully: false,
    };
  },
  created() {
    this.$store.commit("Payment/SET_PAYMENT_URL", null);
    this.runCheck();
    this.saveCard();
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    async chooseCard(cardToken) {
      Dialog.confirm({
        title: this.$t("paymentCards.dialogPayMessage"),
        message: this.$t("paymentCards.dialogPayQuestion"),
        cancelButtonText: this.$t("paymentCards.dialogCancel"),
        confirmButtonText: this.$t("paymentCards.dialogConfirm"),
      })
          .then(async () => {
            await this.$store.commit("Payment/SET_CARD_TOKEN", cardToken);
            await this.preparePaymentPage();
          })
          .catch(() => {});
    },

    async saveCard() {
      await this.$store.commit("Payment/SET_CARD_TOKEN", null);
      let cardType = "balances";
      await this.$store.dispatch("Payment/getCardsList", cardType);
      if (!this.cards.length) {
        await this.preparePaymentPage();
      }
    },
    runCheck() {
      this.watchInterval = setInterval(() => {
        if (this.$refs.payPage !== undefined) {
          try {
            let url = this.$refs.payPage.contentWindow.location.href;
            if (url.match(process.env.VUE_APP_PRODUCTION_URL) !== null) {
              clearInterval(this.watchInterval);
              this.$router.push(
                  url.replace(process.env.VUE_APP_PRODUCTION_URL, "")
              );
            }
          } catch (e) {
            console.log("Waiting");
          }
        }
      }, 200);
    },
    async preparePaymentPage() {
      if (this.account === null) {
        await this.$store.dispatch(
            "Auth/getUserAccount",
            this.$route.params.account
        );
        await this.runPaymentRequest();
      } else {
        await this.runPaymentRequest();
      }
    },
    async runPaymentRequest() {
      await this.$store.commit(
          "Payment/SET_USER_LS",
          this.$route.params.account
      );
      try {
        await this.$router.push({ name: "BalancesPaid" });
      } catch (e) {
        await this.$router.push({ name: "CancelPayment" });
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: calc(100vh - 120px);
}
.iframe-container iframe {
  height: calc(100vh - 120px);
  width: 1px;
  min-width: 100%;
  *width: 100%;
}
.cards-list {
  padding-top: 25px;
}
.cards-list-cell {
  font-size: 18px !important;
}
.card-type-image {
  height: 20px;
  margin-right: 5px;
}
.payment-page {
  width: 100%;
  flex-grow: 1;
  border: none !important;
}
</style>
