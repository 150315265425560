var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-row',{staticClass:"page-wrapper",attrs:{"justify":"center"}},[_c('van-col',{staticClass:"itserve-center mb-5",attrs:{"span":"24"}},[_c('router-link',{attrs:{"to":{ name: 'StripePayment' }}},[(_vm.status === 0)?_c('van-notice-bar',{attrs:{"color":"red","left-icon":"volume-o"}},[_vm._v(" "+_vm._s(_vm.$t("notice-bar.free"))+" ")]):_c('van-notice-bar',{attrs:{"color":"#1989fa","left-icon":"volume-o"}},[_vm._v(" "+_vm._s(_vm.$t("notice-bar.premium"))+" ")])],1),(_vm.account)?[_c('div',{staticClass:"white-wrapper place-content-center mt-15 top-radius"},[_c('div',{staticClass:"apply-loader"},[_c('h5',{staticClass:"page-type account-page top-radius"})])]),_c('account',{staticClass:"home-account-shadow place-content-center",attrs:{"account":_vm.account,"show-balance":false}})]:_vm._e(),_c('div',{staticClass:"main-page-account place-content-center"},[(_vm.account)?[_c('itserve-route',{staticClass:"nav-btn mt-15-simple",attrs:{"route-name":_vm.account.accountVerified === 1 ? 'RegionalEnergy' : 'Verification',"params":{
              account: _vm.$route.params.account,
              requested:
                _vm.account.accountVerified === 1 ? null : 'RegionalEnergy',
            },"buttonName":_vm.$t('buttons.putIndications'),"type":"wide","icon":true,"icon-color":"#3C2C93","btn-style":"box"}},[_c('send-indications-icon')],1)]:_vm._e(),[_c('div',{on:{"click":_vm.runPayment}},[_c('itserve-route',{staticClass:"nav-btn mt-15-simple fr",attrs:{"params":_vm.$route.params,"buttonName":_vm.$t('buttons.pay_erc'),"type":"wide","icon":true,"icon-color":"#1AAD62","btn-style":"box"}},[_c('pay-all-icon')],1)],1)],_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5",attrs:{"route-name":"PaymentHistoryResults","buttonName":_vm.$t('buttons.paymentHistory'),"type":"wide","icon":true,"icon-color":"#000000","btn-style":"box"}},[_c('payment-history-icon')],1),(_vm.account)?[_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5 fr",attrs:{"route-name":_vm.account.accountVerified === 1 ? 'Shutdowns' : 'Verification',"buttonName":_vm.$t('buttons.shutdowns'),"params":{
              account: _vm.$route.params.account,
              requested: _vm.account.accountVerified === 1 ? null : 'Shutdowns',
            },"type":"wide","icon":true,"icon-color":"#FF8573","btn-style":"box"}},[_c('shutdown-icon')],1)]:_vm._e(),_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5",attrs:{"route-name":"WriteTicket","buttonName":_vm.$t('buttons.contactUs'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#FCC204","btn-style":"box"}},[_c('contactus-icon')],1),_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5 fr",attrs:{"route-name":"StripePayment","buttonName":_vm.$t('settings.stripeButtonNew'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"lightgreen","btn-style":"box"}},[_c('dollar-sign-icon')],1),_c('div',{on:{"click":_vm.newsLink}},[_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5",attrs:{"buttonName":_vm.$t('settings.telegramNews'),"params":_vm.$route.params,"type":"wide","icon":true,"newsIcon":true,"btn-style":"box"}},[_c('p',{staticStyle:{"margin-top":"15px"}},[_vm._v("24")])])],1),_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5 fr",attrs:{"route-name":"SelectMeterage","buttonName":_vm.$t('buttons.energyCalculator'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#D08CFA","btn-style":"box"}},[_c('calculator-icon',{attrs:{"size":"85%"}})],1),_c('div',{on:{"click":_vm.socialShare}},[_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5",attrs:{"buttonName":_vm.$t('buttons.socialShare'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#0fb1e5","btn-style":"box"}},[_c('share-icon',{attrs:{"size":"85%"}})],1)],1)],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }