<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
        span="24"
        class="place-content-center round-shadow-btn flex-display-fix"
    >
      <template v-if="paymentHistory">
        <template v-for="payment in paymentHistory">
          <b-button
              v-b-toggle="payment[0].period"
              v-bind:key="payment[0].period"
              size="large"
              class="mt-10"
              type="info">
            {{ payment[0].period }}
          </b-button>
          <b-collapse :id="payment[0].period" class="mt-2" v-bind:key="payment[0].period">
            <b-card>
              <template v-for="message in payment">
                <info-message
                    :key="'msg_' + message.date + '_' + message.period + '_' + message.payment_type"
                    class="history-item"
                >
                  <van-row>
                    <p class="payment-type">
                      {{ message.payment_type }}
                    </p>
                    <van-col span="12" class="payment-history-left apply-loader">
                      {{ $t("paymentHistorySearch.date") }}
                    </van-col>
                    <van-col span="12" class="payment-history-right apply-loader">
                      {{ message.date }}
                    </van-col>
                    <van-col span="12" class="payment-history-left apply-loader">
                      {{ $t("paymentHistorySearch.sum") }}
                    </van-col>
                    <van-col span="12" class="payment-history-right apply-loader">
                      {{ message.sum }}
                    </van-col>
                    <van-col span="12" class="payment-history-left apply-loader">
                      {{ $t("paymentHistorySearch.status") }}
                    </van-col>
                    <van-col v-if="message.status === 0" span="12" class="apply-loader payment-declined">
                      {{ $t("paymentHistorySearch.failed") }}
                    </van-col>
                    <van-col v-else-if="message.status === 1" span="12" class="apply-loader payment-success">
                      {{ $t("paymentHistorySearch.success") }}
                    </van-col>
                    <van-col v-else-if="message.status === 2" span="12" class="apply-loader payment-declined">
                      {{ $t("paymentHistorySearch.declined") }}
                    </van-col>
                    <van-col v-else-if="message.status === 3" span="12" class="apply-loader payment-declined">
                      {{ $t("paymentHistorySearch.pending") }}
                    </van-col>
                  </van-row>
                </info-message>
              </template>
            </b-card>
          </b-collapse>
        </template>
      </template>
      <template v-else>
        <info-message>
          {{ $t("global.noData") }}
        </info-message>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
// import VanButton from "vant/lib/button/index";
export default {
  name: "PaymentHistorySearchResults",
  components: {
    InfoMessage,
    // VanButton
  },
  computed: {
    paymentHistory() {
      return this.$store.state.PaymentHistory.paymentHistory;
    },
  },
  created() {
    this.$store.commit(
        "PaymentHistory/SET_FILTER_VALUES",
        this.getFilterValues()
    );
    this.$store.dispatch("PaymentHistory/getPaymentHistoryNew");
  },
  methods: {
    getFilterValues(){
      return {
        ls: this.$route.params.account,
      };
    },
  }
};
</script>
<style scoped>
.payment-type {
  font-size: 12px;
  margin: 5px 5px 0;
}
.energy-title {
  color: #2e73b4;
  font-size: 18px;
  margin: 5px 5px 0;
}
.payment-history-left,
.payment-history-right {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0b0b0b;
}
.payment-history-right {
  text-align: right;
  padding-right: 15px;
}
.payment-history-left {
  text-align: left;
  padding-left: 15px;
}
.payment-declined {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  color: darkred;
  text-align: right;
}
.app-theme-dark .payment-declined{
  color: red;
}
.payment-success {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  color: darkgreen;
  text-align: right;
}
.app-theme-dark .payment-success{
  color: green;
}
</style>
