<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.apartment")
          }}</info-message>
      </div>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.wait")
          }}</info-message>
      </div>
      <van-form>
        <v-text-field
            :placeholder="$t('addAccount.inputApartment')"
            autocomplete="off"
            v-model="apartment"
            class="mt-10"
            type="text"
        ></v-text-field>
        <v-card v-if="listApartments">
          <v-virtual-scroll
              height="200"
              item-height="64"
              :items="APARTMENTS"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.index">
                <v-list-item-content @click="addApartment(item.apartment)">
                  {{ item.apartment }}
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
        <van-col class="itserve-center" span="24">
          <itserve-route
              :buttonName="$t('buttons.addApartment')"
              route-name="AddLs"
              type="wide"
          ></itserve-route>
        </van-col>
      </van-form>
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";

import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import {VCard, VDivider, VListItem, VListItemContent, VTextField, VVirtualScroll} from "vuetify/lib";
import ItserveRoute from "@/components/content/ItserveRoute";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AddApartment",
  data: () => ({
    apartment: "",
    listApartments: false,
  }),

  watch: {
    apartment() {
      this.listApartments = true;
      this.apartmentsArr();

      let arr = this.APARTMENTS;
      console.log("CHLENIX apartmentName " + JSON.stringify(arr));

      let data = {
        apartment: this.apartment,
      };

      this.setInfoAddedAddress(data);
    },
  },

  computed: {
    ...mapGetters("ManualAddress", ["APARTMENTS", "SELECTED_CITY", "SELECTED_STREET", "SELECTED_STREET_TYP", "SELECTED_BUILDING"]),
  },

  components: {
    ItserveRoute,
    InfoMessage,
    VanForm: Form,
    // VVirtualScroll,
    // VListItemContent,
    // VListItem,
    // VDivider,
    // VCard,
    // VTextField,
  },

  methods: {
    ...mapActions("ManualAddress", ["getBuildsArr", "getApartmentsArr", "getAccountsArr"]),
    ...mapMutations("ManualAddress", ["setInfoAddedAddress"]),

    apartmentsArr() {
      let street = this.SELECTED_STREET.split(" ");

      if (street[2] === undefined) {
        street = street[1];
      } else if (street[3] !== undefined) {
        street = street[1] + " " + street[2] + " " + street[3];
      } else {
        street = street[1] + " " + street[2];
      }
      let data = {
        city: this.SELECTED_CITY,
        street: street,
        street_typ: this.SELECTED_STREET_TYP,
        building: this.SELECTED_BUILDING,
        apartment: this.apartment,
      };
      this.getApartmentsArr(data);
    },

    accountsArr() {
      let street = this.SELECTED_STREET.split(" ");

      if (street[2] === undefined) {
        street = street[1];
      } else if (street[3] !== undefined) {
        street = street[1] + " " + street[2] + " " + street[3];
      } else {
        street = street[1] + " " + street[2];
      }
      let data = {
        city: this.SELECTED_CITY,
        street: street,
        street_typ: this.SELECTED_STREET_TYP,
        building: this.SELECTED_BUILDING,
        apartment: this.apartment,
      };
      this.getAccountsArr(data);
    },

    addApartment(apartment) {
      this.apartment = apartment;
      this.listApartments = false;
      this.accountsArr();
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
<style>
.v-text-field input {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
}
</style>
