<template>
  <div>
    <div class="modal-mask">
  <iframe
      style="width: 100%; height: 100%"
      allow="microphone;"
      src="https://console.dialogflow.com/api-client/demo/embedded/3dcb3fe4-fd05-4562-a15b-99ec07f48195">
  </iframe>
    </div>
<!--    <div class="icon-container">-->
      <div class="icon-box" @click="$emit('close')">
        <close fill-color="black"/>
      </div>
<!--    </div>-->
  </div>
</template>

<script>
import Close from "vue-material-design-icons/Close";
export default {
  name: "dialogFlow",
  components: {
    Close
  },
  methods: {
    goBack() {

    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
}
.icon-box {
  background-color: #fbc02d;
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  line-height: 70px;
  z-index: 999;
  right: 2.5%;
  position: fixed;
  top: 2%;
}
</style>
