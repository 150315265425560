import io from "socket.io-client";
// import store from "@/store";

let instance

export default {
    instance: () => instance,
    connect() {
        instance = null;
        if (!instance) instance = io("https://node-kharkiv-api.billing.city", {'forceNew':true });

        instance.on('connect', this.onConnect)
        instance.on('operationReceived', this.onOperationReceived)
        instance.on('statusUpdate', this.statusUpdate)
        instance.on('disconnect', this.onDisconnect)
        return instance
    },
    onConnect() {
        console.log('user connected')
    },
    onOperationReceived(data) {
        console.log(JSON.stringify(data))
    },
    statusUpdate(data) {
        console.log(JSON.stringify(data))
    },
    onDisconnect(){
        console.log("User disconnected")
    }
}
