import payment from "@/plugins/api/factories/PaymentHistory";

export const state = {
  filterValues: null,
  paymentHistory: {},
};

export const actions = {
  async getPaymentHistory({ commit, state }) {
    await payment.getPaymentHistory(state.filterValues, (response) => {
      commit("SET_PAYMENT_HISTORY", response.history);
    });
  },
  async getPaymentHistoryNew({ commit, state }) {
    console.log(JSON.stringify(state.filterValues));
    await payment.getPaymentHistoryNew(state.filterValues, (response) => {
      console.log(JSON.stringify(response.history))
      commit("SET_PAYMENT_HISTORY", response.history);
    });
  },
};

export const mutations = {
  SET_FILTER_VALUES(state, filterValues) {
    state.filterValues = filterValues;
  },
  SET_PAYMENT_HISTORY(state, paymentHistory) {
    state.paymentHistory = paymentHistory;
  },
};
