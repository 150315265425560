import Vue from "vue";
import VueRouter from "vue-router";
import "@/plugins/nativescript-webview-interface.js";
import Home from "../views/Home.vue";
import AddAccount from "../views/account/AddAccount";
import AddCity from "../views/account/AddCity";
import AddStreet from "../views/account/AddStreet";
import AddBuilding from "../views/account/AddBuilding";
import AddApartment from "../views/account/AddApartment";
import AddLs from "../views/account/AddLs";
import Account from "../views/account/Index";
import AutorizationPage from "../views/autorization/AutorizationPage";
import OfertaPage from "../views/autorization/OfertaPage";
import PolicyPage from "../views/autorization/PolicyPage";
import SupportPage from "../views/account/support/Index";
import WriteTicket from "../views/account/support/WriteTicket";
import Contacts from "../views/account/support/Contacts";
import AppSupport from "../views/account/support/AppSupport";
import Settings from "../views/settings/Index";
import Security from "../views/settings/Security";
import AccountsList from "../views/settings/AccountsList";
import PaymentCards from "../views/settings/PaymentCards";
import PaymentBalancesCards from "../views/settings/PaymentBalancesCards";
import DeleteUser from "../views/settings/DeleteUser";
import Faq from "../views/faq/Index";
import Notifications from "../views/notifications/Index";
import Prices from "../views/account/payment/Prices";
import Verification from "../views/account/regional-energy/Verification";
import ContractVerification from "../views/account/regional-energy/verification/ContractVerification";
import CounterVerification from "../views/account/regional-energy/verification/CounterVerification";
import SurnameVerification from "../views/account/regional-energy/verification/SurnameVerification";
import PaymentHistory from "../views/account/payment/PaymentHistory";
import PaymentPage from "../views/account/payment/PaymentPage";
import BalancePaymentPage from "../views/account/payment/BalancePaymentPage";
import StripePaymentPage from "../views/account/payment/StripePaymentPage";
import PortmonePayment from "../views/account/payment/PortmonePayment";
import AddCardPage from "../views/account/payment/AddCardPage";
import AddBalanceCardPage from "../views/account/payment/AddBalanceCardPage";
import PaymentHistorySearchResults from "../views/account/payment/PaymentHistorySearchResults";
import Pay from "../views/account/payment/Pay";
import PayAll from "../views/account/payment/PayAll";
import PayAllNew from "../views/account/payment/PayAllNew";
import SuccessCard from "../views/account/payment/callbacks/card/Success";
import CancelCard from "../views/account/payment/callbacks/card/Cancel";
import SuccessBalanceCard from "../views/account/payment/callbacks/card/BalanceSuccess";
import CancelBalanceCard from "../views/account/payment/callbacks/card/BalanceCancel";
import SuccessPay from "../views/account/payment/callbacks/payment/Success";
import BalancesPaid from "../views/account/payment/callbacks/payment/BalancesSuccess";
import ConfirmPayment from "../views/account/payment/callbacks/payment/ConfirmPayment";
import CancelPay from "../views/account/payment/callbacks/payment/Cancel";
import RegionalEnergy from "../views/account/regional-energy/Index";
import SendIndications from "../views/account/regional-energy/SendIndications";
import SendIndicationsPhoto from "../views/account/regional-energy/SendIndicationsPhoto";
import SendIndicationsPhotoMainMenu from "../views/account/regional-energy/SendIndicationsPhotoMainMenu";
import IndicationsHistory from "../views/account/regional-energy/IndicationsHistory";
import IndicatorsHistorySearchResults from "../views/account/regional-energy/IndicatorsHistorySearchResults";
import Shutdowns from "../views/account/regional-energy/Shutdowns";
import ReportShutdown from "../views/account/regional-energy/ReportShutdown";
import ContactRegionalEnergy from "../views/account/regional-energy/ContactRegionalEnergy";
import EnterpriseServices from "../views/account/regional-energy/EnterpriseServices";
import CalculationPage from "../views/account/regional-energy/CalculationPage";
import SelectMeterage from "../views/account/regional-energy/SelectMeterage";
import StripePayment from "../views/settings/StripePayment";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home", //Done
    component: Home,
  },
  {
    path: "/account/:account",
    name: "Account",
    component: Account,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/add-account",
    name: "AddAccount",
    component: AddAccount,
    meta: {
      backButton: ["Home", "AccountsList"],
    },
  },
  {
    path: "/city-search",
    name: "AddCity",
    component: AddCity,
    meta: {
      backButton: ["Home", "AddAccount"],
    },
  },
  {
    path: "/street-search",
    name: "AddStreet",
    component: AddStreet,
    meta: {
      backButton: ["AddCity"],
    },
  },
  {
    path: "/building-search",
    name: "AddBuilding",
    component: AddBuilding,
    meta: {
      backButton: ["AddStreet"],
    },
  },
  {
    path: "/apartment-search",
    name: "AddApartment",
    component: AddApartment,
    meta: {
      backButton: ["AddBuilding"],
    },
  },
  {
    path: "/ls-search",
    name: "AddLs",
    component: AddLs,
    meta: {
      backButton: ["AddApartment"],
    },
  },
  {
    path: "/stripe-payment",
    name: "StripePayment",
    component: StripePayment,
    meta: {
      backButton: ["Settings"],
    },
  },
  {
    path: "/support-page",
    name: "SupportPage",
    component: SupportPage,
    meta: {
      backButton: ["WriteTicket"],
    },
  },
  {
    path: "/write-ticket/:account",
    name: "WriteTicket",
    component: WriteTicket,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      backButton: ["WriteTicket"],
    },
  },
  {
    path: "/app-support/:support/:account",
    name: "AppSupport",
    component: AppSupport,
    meta: {
      backButton: ["WriteTicket"],
    },
  },
  {
    path: "/select-meterage",
    name: "SelectMeterage",
    component: SelectMeterage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/autorization",
    name: "AutorizationPage",
    component: AutorizationPage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/oferta",
    name: "OfertaPage",
    component: OfertaPage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/policy",
    name: "PolicyPage",
    component: PolicyPage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/calculation-page:meterage",
    name: "CalculationPage",
    component: CalculationPage,
    meta: {
      backButton: ["SelectMeterage"],
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      backButton: [
        "Home",
        "Account",
        "RegionalEnergy",
        "SendIndicationsPhoto",
        "SendIndications",
        "IndicationsHistory",
        "IndicatorsHistorySearchResults",
        "Shutdowns",
        "ReportShutdown",
        "ContactRegionalEnergy",
        "EnterpriseServices",
        "Pay",
        "PayAll",
        "PaymentHistory",
        "PaymentHistoryResults",
        "SupportPage",
        "WriteTicket",
        "AppSupport",
        "Contacts",
        "Prices",
        "AddPaymentCard",
      ],
    },
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
    meta: {
      backButton: ["Settings"],
    },
  },
  {
    path: "/accounts-list",
    name: "AccountsList",
    component: AccountsList,
    meta: {
      backButton: ["Settings"],
    },
  },
  {
    path: "/payment-cards",
    name: "PaymentCards",
    component: PaymentCards,
    meta: {
      backButton: ["Settings"],
    },
  },
  {
    path: "/payment-balances-cards/:account",
    name: "PaymentBalancesCards",
    component: PaymentBalancesCards,
    meta: {
      backButton: ["PayAll"],
    },
  },
  {
    path: "/delete-user",
    name: "DeleteUser",
    component: DeleteUser,
    meta: {
      backButton: ["Settings"],
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
    meta: {
      backButton: ["WriteTicket"],
    },
  },
  {
    path: "/notifications/:account?",
    name: "Notifications",
    component: Notifications,
    meta: {
      backButton: [
        "Home",
        "Account",
        "RegionalEnergy",
        "SendIndicationsPhoto",
        "SendIndications",
        "IndicationsHistory",
        "IndicatorsHistorySearchResults",
        "Shutdowns",
        "ReportShutdown",
        "ContactRegionalEnergy",
        "EnterpriseServices",
        "Settings",
        "AccountsList",
        "AddAccount",
        "AddCity",
        "Pay",
        "PayAll",
        "PaymentHistory",
        "PaymentHistoryResults",
        "SupportPage",
        "WriteTicket",
        "AppSupport",
        "Contacts",
        "Prices",
        "PaymentCards",
        "AddPaymentCard",
        "Security",
      ],
    },
  },
  {
    path: "/prices/:account",
    name: "Prices",
    component: Prices,
    meta: {
      backButton: ["Pay"],
    },
  },
  {
    path: "/verification/:account/:requested?",
    name: "Verification",
    component: Verification,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/contract-verification/:account/:requested?",
    name: "ContractVerification",
    component: ContractVerification,
    meta: {
      backButton: ["Verification"],
    },
  },
  {
    path: "/counter-verification/:account/:requested?",
    name: "CounterVerification",
    component: CounterVerification,
    meta: {
      backButton: ["Verification"],
    },
  },
  {
    path: "/surname-verification/:account/:requested?",
    name: "SurnameVerification",
    component: SurnameVerification,
    meta: {
      backButton: ["Verification"],
    },
  },
  {
    path: "/payment-history/:account",
    name: "PaymentHistory",
    component: PaymentHistory,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/easy-pay/pay/:account",
    name: "EasyPayPayment",
    component: PaymentPage,
    meta: {
      backButton: ["Account", "Pay"],
    },
  },
  {
    path: "/portmone/balance/:account",
    name: "PortmonePayment",
    component: PortmonePayment,
    meta: {
      backButton: ["Account", "PayAll"],
    },
  },
  {
    path: "/easy-pay/balance/:account",
    name: "BalancePayment",
    component: BalancePaymentPage,
    meta: {
      backButton: ["Account", "PayAll"],
    },
  },
  {
    path: "/stripe/payment/:account",
    name: "StripePaymentPage",
    component: StripePaymentPage,
    meta: {
      backButton: ["Account", "PayAll"],
    },
  },
  {
    path: "/easy-pay/add-card/",
    name: "AddCard",
    component: AddCardPage,
    meta: {
      backButton: ["PaymentCards", "PayAll"],
    },
  },
  {
    path: "/easy-pay/add-balance-card/",
    name: "AddBalanceCard",
    component: AddBalanceCardPage,
    meta: {
      backButton: ["PayAll"],
    },
  },
  {
    path: "/payment-history-results/:account",
    name: "PaymentHistoryResults",
    component: PaymentHistorySearchResults,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/pay/:account",
    name: "Pay",
    component: Pay,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/pay/:account",
    name: "PayAll",
    component: PayAll,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/pay-new/:account",
    name: "PayAllNew",
    component: PayAllNew,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/card/success",
    name: "SuccessfullyCard",
    component: SuccessCard,
    meta: {
      backButton: ["PaymentCards"],
    },
  },
  {
    path: "/card/cancel",
    name: "CancelCard",
    component: CancelCard,
    meta: {
      backButton: ["PaymentCards"],
    },
  },
  {
    path: "/card/balance-success/:cardToken",
    name: "SuccessfullyBalanceCard",
    component: SuccessBalanceCard,
    meta: {
      backButton: ["PaymentBalancesCards"],
    },
  },
  {
    path: "/card/balance-cancel",
    name: "CancelBalanceCard",
    component: CancelBalanceCard,
    meta: {
      backButton: ["PaymentBalancesCards"],
    },
  },
  {
    path: "/payment/success",
    name: "SuccessfullyPaid",
    component: SuccessPay,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/payment/balances-success",
    name: "BalancesPaid",
    component: BalancesPaid,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/payment/confirm",
    name: "PaymentConfirm",
    component: ConfirmPayment,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/payment/cancel",
    name: "CancelPayment",
    component: CancelPay,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/regional-energy/:account",
    name: "RegionalEnergy",
    component: RegionalEnergy,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/regional-energy-indications/:account",
    name: "SendIndications",
    component: SendIndications,
    meta: {
      backButton: ["RegionalEnergy"],
    },
  },
  {
    path: "/regional-energy-indications-photo/:account",
    name: "SendIndicationsPhoto",
    component: SendIndicationsPhoto,
    meta: {
      backButton: ["SendIndications"],
    },
  },
  {
    path: "/regional-energy-indications-photo-main/:account",
    name: "SendIndicationsPhotoMainMenu",
    component: SendIndicationsPhotoMainMenu,
    meta: {
      backButton: ["RegionalEnergy"],
    },
  },
  {
    path: "/regional-energy-indications-history/:account",
    name: "IndicationsHistory",
    component: IndicationsHistory,
    meta: {
      backButton: ["RegionalEnergy", "SendIndications"],
    },
  },
  {
    path: "/regional-energy-indications-history-result/:start_date/:end_date/:account",
    name: "IndicatorsHistorySearchResults",
    component: IndicatorsHistorySearchResults,
    meta: {
      backButton: ["IndicationsHistory"],
    },
  },
  {
    path: "/regional-energy-shutdowns/:account",
    name: "Shutdowns",
    component: Shutdowns,
    meta: {
      backButton: ["Account"],
    },
  },
  {
    path: "/report-shutdown/:account",
    name: "ReportShutdown",
    component: ReportShutdown,
    meta: {
      backButton: ["Shutdowns"],
    },
  },
  {
    path: "/regional-energy-contacts",
    name: "ContactRegionalEnergy",
    component: ContactRegionalEnergy,
    meta: {
      backButton: ["RegionalEnergy"],
    },
  },
  {
    path: "/regional-energy-services",
    name: "EnterpriseServices",
    component: EnterpriseServices,
    meta: {
      backButton: ["RegionalEnergy"],
    },
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("FROM ROUTE NAME : " + from.name);
  console.log("TO ROUTE NAME : " + to.name);
  if (from.name !== null) {
    if (
        from.name === "SendIndications" &&
        router.app.$store.state.Flashlight.flashlightEnabled
    ) {
      router.app.$store.dispatch("Flashlight/disableFlashlight");
    }

    let params = Object.keys(from.params).length ? from.params : null;
    router.app.$store.commit("BackButton/SET_PREVIOUS_ROUTE", {
      name: from.name,
      params,
    });
    router.app.$store.commit("BackButton/SET_CURRENT_ROUTE", to.name);
  } else {
    router.app.$store.commit("BackButton/SET_PREVIOUS_ROUTE", {
      name: "Home",
    });
  }
  next();
});

export default router;
