<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
        span="24"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.city")
          }}
        </info-message>
      </div>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.wait")
          }}
        </info-message>
      </div>

      <v-text-field
          v-model="city"
          :placeholder="$t('addAccount.inputCity')"
          class="mt-10"
      ></v-text-field>
      <v-card v-if="listCity">
        <v-virtual-scroll
            :items="CITIES"
            height="200"
            item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.index">
              <v-list-item-content @click="addCity(item.city)">
                {{ item.city }}
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card>
      <van-col class="itserve-center" span="24">
        <itserve-route
            v-if="showBtn"
            :buttonName="$t('buttons.addCity')"
            route-name="AddStreet"
            type="wide"
        ></itserve-route>
      </van-col>
    </van-col>
  </van-row>
</template>
<script>

import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import {VVirtualScroll} from "vuetify/lib";
import ItserveRoute from "@/components/content/ItserveRoute";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AddCity",

  data: () => ({
    city: "",
    listCity: false,
    showBtn: false,
  }),

  watch: {
    city() {
      this.listCity = true;
      this.citiesArr();

      let arr = this.CITIES;

      if (arr !== undefined) {

        for (let i = 0; i < arr.length; i++) {

          let cityName = arr[i].city;

          if (cityName === this.city) {
            this.listCity = false;
            this.showBtn = true;
            let data = {
              city: this.city,
            };
            this.setInfoAddedAddress(data);
          }
        }
      }
    },
  },

  computed: {
    ...mapGetters("ManualAddress", ["CITIES"]),
  },

  components: {
    ItserveRoute,
    InfoMessage,
    // VVirtualScroll,
  },

  methods: {

    ...mapActions("ManualAddress", ["getCitiesArr"]),
    ...mapMutations("ManualAddress", ["setInfoAddedAddress"]),

    citiesArr() {
      let data = {
        city: this.city,
      };
      this.getCitiesArr(data);
    },

    addCity(city) {
      this.city = city;
      this.listCity = false;
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
<style>

.v-list-item__content{
  padding: 0px 10px 10px 0px;
}

.v-virtual-scroll {
  margin-right: 10%;
  margin-left: 10%;
  display: block;
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
}

.v-virtual-scroll__container{
  display: block;
}

.v-virtual-scroll__item{
  padding-top: 30px;
  left: 0;
  position: absolute;
  right: 0;
}

.v-text-field input {
  text-align: center !important;
  text-align: -moz-center;
  text-align: -webkit-center;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
