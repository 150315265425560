<template>
  <router-link
    :to="{ name: routeName, params }"
    class="itserve-route-button"
    :class="btnStyle === 'box' ? 'box-route' : ''"
  >
    <itserve-button
      :value="buttonName"
      :class="type === 'wide' ? 'itserve-button-wide' : ''"
      :icon="icon"
      :animated="animated"
      :newsIcon="newsIcon"
      type="primary"
      :icon-color="iconColor"
      class="apply-loader"
      :btn-style="btnStyle"
      ><slot></slot
    ></itserve-button>
  </router-link>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
export default {
  name: "ItserveRoute",
  components: { ItserveButton },
  props: {
    routeName: String,
    buttonName: String,
    type: String,
    params: Object,
    icon: Boolean,
    animated: Boolean,
    newsIcon: Boolean,
    iconColor: String,
    btnStyle: String,
  },
};
</script>
