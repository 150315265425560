<template>
  <svg
    width="14"
    height="20"
    style="margin-top: 6px"
    viewBox="0 0 42 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.9144 0H12.4286C12.089 0 11.798 0.220581 11.6718 0.535697L0.242557 12.9092C0.242557 12.9092 0.242557 12.9197 0.232854 12.9197C0.164938 13.0038 0.0970226 13.0983 0.0582136 13.2033C0.0194045 13.3084 0 13.4239 0 13.5395V63.1072C0 63.6009 0.368686 64 0.824692 64H35.6461C39.0031 64 41.7391 61.0379 41.7391 57.4036V0.892828C41.7391 0.399147 41.3704 0 40.9144 0ZM11.6039 3.14065V12.6466H2.81366L11.6039 3.14065ZM40.0897 57.4036C40.0897 60.0611 38.0911 62.2143 35.6461 62.2143H1.64938V14.4323H12.4286C12.8846 14.4323 13.2533 14.0332 13.2533 13.5395V1.78566H40.0897V57.4036Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "prices-icon",
};
</script>

<style scoped></style>
