<template>
  <transition name="addPhone">
    <div class="modal-mask">
  <van-row justify="center" class="page-wrapper">
  <div class="place-content-center" style="background-color: #474545;">
    <van-form>
      <info-message class="apply-loader settings-message" style="color: #fff;">
        {{ $t("popup.addPhone") }}
      </info-message>
      <itserve-input
          v-model="newPhone"
          inputmode="tel"
          type="text"
          class="apply-loader fl-upper"
          :placeholder="$t('popup.phone')"
          name="name"
      />
    </van-form>
    <itserve-button
        type="primary"
        class="itserve-round-btn itserve-button-wide mb-25 apply-loader"
        :value="$t('buttons.saveChanges')"
        @click="addPhone"
    ></itserve-button>
  </div>
  <simple-dialog
      v-model="showDialog2"
      :success="actionSuccessfully"
      :title="dialogMessage"
  />
  <simple-dialog
      v-model="showDialog3"
      :success="actionSuccessfully"
      :title="dialogMessage"
  />
  </van-row>
    </div>
  </transition>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  name: "addPhone",
  components: {
    ItserveButton,
    ItserveInput,
    InfoMessage,
    SimpleDialog

  },
  data() {
    return {
      showDialog: false,
      showDialog2: false,
      showDialog3: false,
      dialogMessage: "",
      actionSuccessfully: false,
    };
  },
  computed: {
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
    newPhone: {
      get() {
        return this.$store.state.Settings.newPhone;
      },
      set(newPhone) {
        this.$store.commit("Settings/UPDATE_PHONE", newPhone);
        this.dialogMessage = this.$t("settings.successMessage");
      },
    },
  },
  methods: {
    addPhone() {
      if (this.$store.state.Settings.newPhone === null) {
        this.showDialog3 = true;
        this.dialogMessage = this.$t("settings.emptyField");
        this.actionSuccessfully = false;
      }
      if (this.$store.state.Settings.newPhone.length !== 13 || this.$store.state.Settings.newPhone.substr(0, 1) !== "+") {
        this.showDialog3 = true;
        this.dialogMessage = this.$t("settings.falseMessage");
        this.actionSuccessfully = false;
      } else {
        this.$store.dispatch("Settings/storePhone").then((res) => {
          if (res) {
            this.showDialog2 = true;
            this.$store.state.Auth.phoneNumber = this.newPhone;
          } else {
            this.showDialog2 = true;
            this.dialogMessage = this.$t("settings.error");
          }
        });
      }
    },
    confirm() {
      this.$store.state.Auth.phoneNumber = this.newPhone;
    },
  }
}
</script>

<style scoped>
.modal-mask {
  padding-top: 20%;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

</style>