<template>
  <van-row class="page-wrapper" justify="center">

    <van-col class="itserve-center mb-5" span="24">
      <router-link :to="{ name: 'StripePayment' }">
        <van-notice-bar v-if="status === 0" color="red"
         left-icon="volume-o" >
          {{ $t("notice-bar.free") }}
        </van-notice-bar>
        <van-notice-bar v-else color="#1989fa"
                        left-icon="volume-o" >
          {{ $t("notice-bar.premium") }}
        </van-notice-bar>
      </router-link>
      <template v-if="account">
        <div class="white-wrapper place-content-center mt-15 top-radius">
          <div class="apply-loader">
            <h5 class="page-type account-page top-radius">

            </h5>
          </div>
        </div>
        <account
          :account="account"
          :show-balance="false"
          class="home-account-shadow place-content-center"
        />
      </template>

      <div class="main-page-account place-content-center">
        <template v-if="account">
          <itserve-route
            :route-name="
              account.accountVerified === 1 ? 'RegionalEnergy' : 'Verification'
            "
            :params="{
              account: $route.params.account,
              requested:
                account.accountVerified === 1 ? null : 'RegionalEnergy',
            }"
            :buttonName="$t('buttons.putIndications')"
            type="wide"
            class="nav-btn mt-15-simple"
            :icon="true"
            icon-color="#3C2C93"
            btn-style="box"
          >
            <send-indications-icon />
          </itserve-route>
        </template>
        <template>
          <div @click="runPayment">
        <itserve-route
            :params="$route.params"
            :buttonName="$t('buttons.pay_erc')"
            type="wide"
            class="nav-btn mt-15-simple fr"
            :icon="true"
            icon-color="#1AAD62"
            btn-style="box"
        >
          <pay-all-icon />
        </itserve-route>
          </div>
        </template>
<!--        <template v-else>-->
<!--        <itserve-route-->
<!--          route-name="Pay"-->
<!--          :params="$route.params"-->
<!--          :buttonName="$t('buttons.pay')"-->
<!--          type="wide"-->
<!--          class="nav-btn mt-15-simple fr"-->
<!--          :icon="true"-->
<!--          icon-color="#1AAD62"-->
<!--          btn-style="box"-->
<!--        >-->
<!--          <pay-icon />-->
<!--        </itserve-route>-->
<!--        </template>-->
        <itserve-route
          route-name="PaymentHistoryResults"
          :buttonName="$t('buttons.paymentHistory')"
          type="wide"
          class="nav-btn mt-15-simple mt-5"
          :icon="true"
          icon-color="#000000"
          btn-style="box"
        >
          <payment-history-icon />
        </itserve-route>
        <template v-if="account">
          <itserve-route
            :route-name="
              account.accountVerified === 1 ? 'Shutdowns' : 'Verification'
            "
            :buttonName="$t('buttons.shutdowns')"
            :params="{
              account: $route.params.account,
              requested: account.accountVerified === 1 ? null : 'Shutdowns',
            }"
            type="wide"
            class="nav-btn mt-15-simple mt-5 fr"
            :icon="true"
            icon-color="#FF8573"
            btn-style="box"
          >
            <shutdown-icon />
          </itserve-route>
        </template>

        <itserve-route
          route-name="WriteTicket"
          :buttonName="$t('buttons.contactUs')"
          :params="$route.params"
          type="wide"
          class="nav-btn mt-15-simple mt-5"
          :icon="true"
          icon-color="#FCC204"
          btn-style="box"
        >
          <contactus-icon />
        </itserve-route>

        <itserve-route
            route-name="StripePayment"
            :buttonName="$t('settings.stripeButtonNew')"
            :params="$route.params"
            type="wide"
            class="nav-btn mt-15-simple mt-5 fr"
            :icon="true"
            icon-color="lightgreen"
            btn-style="box"
        >
          <dollar-sign-icon />
        </itserve-route>

        <div @click="newsLink">
          <itserve-route
              :buttonName="$t('settings.telegramNews')"
              :params="$route.params"
              type="wide"
              class="nav-btn mt-15-simple mt-5"
              :icon="true"
              :newsIcon="true"
              btn-style="box"
          >
            <p style="margin-top: 15px">24</p>
          </itserve-route>
        </div>

        <itserve-route
          route-name="SelectMeterage"
          :buttonName="$t('buttons.energyCalculator')"
          :params="$route.params"
          type="wide"
          class="nav-btn mt-15-simple mt-5 fr"
          :icon="true"
          icon-color="#D08CFA"
          btn-style="box"
        >
          <calculator-icon size="85%" />
        </itserve-route>

        <div @click="socialShare">
          <itserve-route
              :buttonName="$t('buttons.socialShare')"
              :params="$route.params"
              type="wide"
              class="nav-btn mt-15-simple mt-5"
              :icon="true"
              icon-color="#0fb1e5"
              btn-style="box"
          >
            <share-icon size="85%" />
          </itserve-route>
        </div>

      </div>
    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import Account from "@/components/helpers/Account";
// import PayIcon from "@/components/icons/pay-icon-box";
import ShutdownIcon from "@/components/icons/shutdown-icon-box";
import ContactusIcon from "@/components/icons/contactus-icon-box";
import SendIndicationsIcon from "@/components/icons/send-indications-icon-box";
import PaymentHistoryIcon from "@/components/icons/payment-history-icon-box";
import CalculatorIcon from "@/components/icons/calculator-icon";
// import NewsIcon from "@/components/icons/news-icon";
//import ElectricIcon from "@/components/icons/electric-icon-box";
import ShareIcon from "vue-material-design-icons/Share";
import PayAllIcon from "@/components/icons/pay-icon-box";
import VanNoticeBar from "vant/lib/notice-bar/index";
import DollarSignIcon from "@/components/icons/dollar-sign-icon";
// import Dialog from "vant/lib/dialog/index";
export default {
  name: "Index",
  components: {
    VanNoticeBar,
    SendIndicationsIcon,
    PaymentHistoryIcon,
    ContactusIcon,
    ShutdownIcon,
    // PayIcon,
    Account,
    // NewsIcon,
    ItserveRoute,
    CalculatorIcon,
    //ElectricIcon,
    ShareIcon,
    DollarSignIcon,
    PayAllIcon
  },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
    status() {
      console.log("USER STATUS: " + this.$store.state.Auth.status);
      return this.$store.state.Auth.status;
    }
  },
  created() {
    this.getPageData();
  },

  methods: {
    openUrl(){
      this.nativeSendEvent("url", "https://cutt.ly/w5Ug1hs");
    },
    newsLink(){
      this.nativeSendEvent("url", "https://t.me/napulsi24");
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text: this.$t("settings.title") + " " + process.env.VUE_APP_SOCIAL_SHARE,
        subject: this.$t("settings.subject"),
      })
    },
    async getPageData() {
      await this.$store.dispatch("Settings/getSettings");
      await this.$store.dispatch("Auth/getSubscriptionStatus");
      await this.checkForCorrectParameters();
      let cardType = "balances";
      await this.$store.dispatch("Payment/getCardsList", cardType);
    },
    async runPayment() {
      await this.$router.push({
        name: "PayAll",
        params: this.$route.params,
      });
      // if(this.status === 1) {
      //   let url = "https://easypay.ua/ua/catalog/utility/harkov/erc-communal-term?account=" + this.account.ls + "&submit=true";
      //   this.nativeSendEvent("url", url);
      // }
      // else{
      //   await this.$router.push({
      //     name: "PayAllNew",
      //     params: this.$route.params,
      //   });
      // }
    },
    async checkForCorrectParameters() {
      if (Object.keys(this.$route.params).length) {
        if (this.$route.params.account !== undefined) {
          await this.$store.dispatch(
            "Auth/getUserAccount",
            this.$route.params.account
          );
        } else {
          console.error(
            "WARNING: somehow user opened account page without provided account parameter!"
          );
          await this.$router.push({ name: "Home" });
        }
      } else {
        console.error(
          "WARNING: somehow user opened account page any parameters!"
        );
        await this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";

.main-page-account {
  padding-bottom: 45px;
}
.page-type {
  padding: 15px 0 0 0;
  font-size: 18px;
  margin: 0;
  color: #0b0b0b;
  background-color: #fff;
}
.home-account-shadow {
  border-radius: 0 0 15px 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
}
.top-radius {
  border-radius: 15px 15px 0 0;
}
</style>
