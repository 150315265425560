<template>
  <div class="itserve-center">
    <div class="place-content-center">
      <div class="itserve-info-message-new">
        <span class="info-content-new">
          <slot>{{
              $t('autorizationPage.autorization')
            }}</slot>
        </span>
      </div>
    </div>
    <van-col class="itserve-center mb-15" span="24">
      <van-button
          @click="googleSign"
          round
          size="large"
          type="info"
          class="home-account-shadow">
        <img
            src="@/assets/images/icons8-google-48.png"
            width="30px"
            style="vertical-align: middle;"
        />
        <span style="margin-left: 15px; vertical-align: middle;">{{ $t('buttons.google') }}</span>
      </van-button>
    </van-col>
    <template v-if="nativeData.platform === 'ios'">
      <van-col class="itserve-center mb-15" span="24">
        <van-button
            @click="appleSign"
            round
            size="large"
            type="info"
            class="mt-15 apply-loader home-account-shadow">
          <img
              src="@/assets/images/apple-logo-svgrepo-com.svg"
              width="30px"
              style="vertical-align: middle;"
          />
          <span style="margin-left: 15px; vertical-align: middle;">{{ $t('buttons.apple') }}</span>
        </van-button>
      </van-col>
    </template>
  </div>
</template>
<script>
import "@/plugins/nativescript-webview-interface.js";
// import ItserveButton from "@/components/forms/ItserveButton";
import VanButton from "vant/lib/button/index";
export default {
  name: "AutorizationPage",
  created() {},
  computed: {
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
  },
  methods: {
    googleSign() {
      this.nativeSendEvent("googleSignin");
      //this.$store.state.Auth.nativeData = null;
    },

    appleSign() {
      this.nativeSendEvent("appleSignin");
    },
  },
  components: {
    // ItserveButton,
    VanButton,
  },
};
</script>
<style scoped>
.home-account-shadow {
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
}
.itserve-info-message-new {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0b0b0b;
  font-size: 18px;
  text-align: center;
  max-width: 337px;
}
.app-theme-dark .itserve-info-message-new{
  color: #ffffff;
}
.info-content-new {
  max-width: 317px;
  display: inline-block;
  text-align: left;
  padding: 10px;
}
</style>
