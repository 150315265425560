<template>
  <van-row class="page-wrapper" justify="center">
    <div class="white-color center mt15">
      {{ $t('regionalEnergy.pay_history') }}
    </div>
    <indications-history-search-form />
  </van-row>
</template>
<script>
import IndicationsHistorySearchForm from "@/components/history/IndicationsHistorySearchForm";
export default {
  name: "IndicationsHistory",
  components: { IndicationsHistorySearchForm },
};
</script>
<style scoped>
.app-theme-dark .white-color {
  color: #fff;
}

.white-color{
  margin-top: 10px;
}

.center {
  text-align: center;
}
.page-wrapper {
  background-color: #ffffff;
}
</style>
