<template>
  <van-col
    span="24"
    class="itserve-center bottom-content-grow flex-display-fix"
  >
    <template v-if="photoRequired">
      <img :src="lastImageBase64" />
      <span class="uploader-status-bar">
        {{ $t("components.sendCounterPhoto.makeAPhotoInstruction") }}
      </span>
      <b class="status-photo">
        <template v-if="lastIndicators.nzone === 2">
          <template v-if="currentZone === 1">
            {{ $t("components.sendCounterPhoto.makeAPhotoWhole") }}
          </template>
          <template v-if="currentZone === 2">
            {{ $t("components.sendCounterPhoto.makeAPhotoHalfDay") }}
          </template>
          <template v-if="currentZone === 3">
            {{ $t("components.sendCounterPhoto.makeAPhotoHalfNight") }}
          </template>
        </template>
        <template v-if="lastIndicators.nzone === 3">
          <template v-if="currentZone === 1">
            {{ $t("components.sendCounterPhoto.makeAPhotoWhole") }}
          </template>
          <template v-if="currentZone === 2">
            {{ $t("components.sendCounterPhoto.makeAPhotoPeak") }}
          </template>
          <template v-if="currentZone === 3">
            {{ $t("components.sendCounterPhoto.makeAPhotoHalfPeak") }}
          </template>
          <template v-if="currentZone === 4">
            {{ $t("components.sendCounterPhoto.makeAPhotoHalfNight") }}
          </template>
        </template>
      </b>
      <itserve-button
        type="primary"
        :value="$t('sendPhotoPage.sendIndicationsPhotoZoneBtn')"
        class="itserve-button-wide mt-15 apply-loader place-content-center"
        @click="takeAPhoto"
      />
      <!-- <van-uploader :after-read="zonePhotoTaken">
        <itserve-button
          type="primary"
          :value="$t('sendPhotoPage.sendIndicationsPhotoZoneBtn')"
          class="itserve-button-wide mt-15 apply-loader place-content-center"
        />
      </van-uploader> -->
    </template>
    <template v-else>
      <div class="place-content-center">
        <div class="center-text">
          <template v-if="photoWasSent">
            <span class="mb-25 uploader-status-bar">{{
              $t("components.sendCounterPhoto.indicationsWasSent")
            }}</span>
            <itserve-route
              route-name="Account"
              :buttonName="$t('buttons.backToMenu')"
              :params="$route.params"
              type="wide"
              class="nav-btn mt-15 back-to-menu"
            >
            </itserve-route>
          </template>
          <template v-else>
            <template v-if="uploadStarted">
              <span class="mb-25 uploader-status-bar"
                >{{ $t("components.sendCounterPhoto.imageUploading") }}
                {{ currentImageSendingName }}</span
              >
              <van-progress :percentage="uploadingProgress" />
            </template>
            <template v-else>
              <span class="mb-25 uploader-status-bar">{{
                $t("components.sendCounterPhoto.imageReadyToUpload")
              }}</span>
              <itserve-button
                type="primary"
                :value="$t('sendPhotoPage.sendImage')"
                class="
                  itserve-button-wide
                  mt-15
                  apply-loader
                  place-content-center
                "
                @click="uploadCounterPhoto"
              />
            </template>
          </template>
        </div>
      </div>
    </template>
    <simple-dialog
      v-model="showErrorMessage"
      :title="$t('components.sendCounterPhoto.tooLong')"
      :success="false"
    />
  </van-col>
</template>

<script>
import ItserveButton from "@/components/forms/ItserveButton";
//import Uploader from "vant/lib/uploader/index";
import Progress from "vant/lib/progress/index";
//import imageCompression from "browser-image-compression";
import ItserveRoute from "@/components/content/ItserveRoute";
import cloneDeep from "lodash.clonedeep";
import SimpleDialog from "@/components/content/SimpleDialog";

export default {
  name: "SendCounterPhoto",
  components: {
    SimpleDialog,
    ItserveButton,
    ItserveRoute,
    // VanUploader: Uploader,
    VanProgress: Progress,
  },
  computed: {
    lastIndicators() {
      return this.$store.state.RegionalEnergy.lastIndicators;
    },
    uploadingProgress() {
      return this.$store.state.Api.uploadingProgress;
    },
  },
  data() {
    return {
      currentZone: 1,
      lastImageBase64: null,
      photoRequired: true,
      uploadStarted: false,
      showErrorMessage: false,
      currentImageSendingName: "",
      photoWasSent: false,
      defaultData: {
        account: this.$route.params.account,
        status: 0,
        userPhoto: null,
      },
      counterData: {
        singleZone: null,
        twoZone: {
          all: null,
          day: null,
          night: null,
        },
        threeZone: {
          all: null,
          peak: null,
          halfPeak: null,
          night: null,
        },
      },
    };
  },
  created() {
    this.$store.dispatch(
      "RegionalEnergy/getLastIndications",
      this.$route.params.account
    );
    this.catchPhotoFromNative();
  },
  methods: {
    takeAPhoto() {
      this.nativeSendEvent("camera");
    },
    catchPhotoFromNative() {
      this.nativeWaitForEvent("photo", (file) => {
        file = this.convertImage(file);
        this.zonePhotoTaken(file);
      });
    },
    async zonePhotoTaken(file) {
      if (this.lastIndicators.nzone === 1) {
        this.counterData.singleZone = cloneDeep(this.defaultData);
        this.counterData.singleZone.userPhoto = file;
        this.photoRequired = false;
      }
      if (this.lastIndicators.nzone === 2) {
        if (this.currentZone === 1) {
          this.counterData.twoZone.all = cloneDeep(this.defaultData);
          this.counterData.twoZone.all.userPhoto = file;
        }

        if (this.currentZone === 2) {
          this.counterData.twoZone.day = cloneDeep(this.defaultData);
          this.counterData.twoZone.day.userPhoto = file;
        }
        if (this.currentZone === 3) {
          this.counterData.twoZone.night = cloneDeep(this.defaultData);
          this.counterData.twoZone.night.userPhoto = file;
        }
        if (this.currentZone <= 2) {
          this.currentZone++;
        } else {
          this.photoRequired = false;
        }
      }

      if (this.lastIndicators.nzone === 3) {
        if (this.currentZone === 1) {
          this.counterData.threeZone.all = cloneDeep(this.defaultData);
          this.counterData.threeZone.all.userPhoto = file;
        }

        if (this.currentZone === 2) {
          this.counterData.threeZone.peak = cloneDeep(this.defaultData);
          this.counterData.threeZone.peak.userPhoto = file;
        }
        if (this.currentZone === 3) {
          this.counterData.threeZone.halfPeak = cloneDeep(this.defaultData);
          this.counterData.threeZone.halfPeak.userPhoto = file;
        }
        if (this.currentZone === 4) {
          this.counterData.threeZone.night = cloneDeep(this.defaultData);
          this.counterData.threeZone.night.userPhoto = file;
        }

        if (this.currentZone <= 3) {
          this.currentZone++;
        } else {
          this.photoRequired = false;
          console.log(this.counterData.threeZone);
        }
      }
    },
    async uploadData(data, zones = 1, zone = 0) {
      this.uploadStarted = true;
      await this.$store.dispatch("RegionalEnergy/uploadCounterPhoto", {
        data,
        zones,
        zone,
      });
      this.uploadStarted = false;
    },
    getDaysBack(timestamp) {
      const date1 = new Date();
      const date2 = new Date(timestamp);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return Math.abs(diffInDays);
    },
    async uploadCounterPhoto() {
      if (this.lastIndicators.nzone === 1) {
        await this.uploadData(this.counterData.singleZone);
        this.photoWasSent = true;
      }
      if (this.lastIndicators.nzone === 2) {
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoWhole"
        );
        await this.uploadData(
          this.counterData.twoZone.all,
          this.lastIndicators.nzone,
          0
        );
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoHalfDay"
        );
        await this.uploadData(
          this.counterData.twoZone.day,
          this.lastIndicators.nzone,
          2
        );
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoHalfNight"
        );
        await this.uploadData(
          this.counterData.twoZone.night,
          this.lastIndicators.nzone,
          3
        );
        this.photoWasSent = true;
      }

      if (this.lastIndicators.nzone === 3) {
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoWhole"
        );
        await this.uploadData(
          this.counterData.threeZone.all,
          this.lastIndicators.nzone,
          0
        );
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoPeak"
        );
        await this.uploadData(
          this.counterData.threeZone.peak,
          this.lastIndicators.nzone,
          1
        );
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoHalfPeak"
        );
        await this.uploadData(
          this.counterData.threeZone.halfPeak,
          this.lastIndicators.nzone,
          2
        );
        this.currentImageSendingName = this.$t(
          "components.sendCounterPhoto.makeAPhotoHalfNight"
        );
        await this.uploadData(
          this.counterData.threeZone.night,
          this.lastIndicators.nzone,
          3
        );
        this.photoWasSent = true;
      }
    },
    convertImage(b64Data, contentType = "image/jpeg", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
};
</script>
<style>
.send-indications-photo .van-uploader__input-wrapper {
  width: 100%;
}
.indications-send-text .info-content {
  text-align: center !important;
}
.back-to-menu .btn-content {
  text-align: center;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/index.css";
@import "../../../node_modules/vant/lib/image/index.css";
@import "../../../node_modules/vant/lib/progress/index.css";
.status-photo {
  text-transform: uppercase;
}

.uploader-status-bar {
  display: inline-block;
}

.image-preview {
  max-width: 200px;
}
.place-content-center {
  width: 100%;
}

.center-text {
  text-align: center;
  max-width: 337px !important;
  width: 100%;
}
</style>
