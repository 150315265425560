<template>
  <van-row class="white-page-bg payment-cb">
    <van-col span="24">
      <info-message class="itserve-center place-content-center">
        <img
            src="@/assets/images/action_success.png"
            alt="success-action"
        /><br />
        {{ $t("pay.cardAdded") }}
      </info-message>
    </van-col>

  </van-row>
</template>

<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  components: {
    InfoMessage,
  },
  name: "BalanceSuccess",
  created() {
    this.runPayment();
  },
  computed: {
    // showLoader() {
    //   return this.$store.state.Api.loading;
    // },
    // paymentData() {
    //   return this.$store.state.Payment.paymentData;
    // },
    // paymentResponse() {
    //   return this.$store.state.Payment.paymentResponse;
    // },
    // paymentUrl() {
    //   return this.$store.state.Payment.paymentUrl;
    // },
    account() {
      return this.$store.state.Auth.account;
    },
    // cards() {
    //   return this.$store.state.Payment.cards;
    // },
    total() {
      return this.$store.state.Auth.total;
    }
  },
  methods: {
    async runPayment(){
      await this.$store.commit("Payment/SET_CARD_TOKEN", this.$route.params.cardToken);
      // await this.$router.push({ name: "BalancesPaid" });
    }
  },

};
</script>

<style scoped>
.van-dialog__content button span {
  color: red!important;
}
</style>
