<template>
  <div>
    <van-col span="24">
      <table class="send-indications-table">
        <tr>
          <th class="apply-loader">
            {{ $t("regionalEnergy.sendIndications.previousIndications") }}
          </th>
          <th class="apply-loader">
            {{ $t("regionalEnergy.sendIndications.newIndications") }}
          </th>
        </tr>
        <template v-if="errors !== null">
          <template
            v-if="Object.keys(errors.errors).indexOf('imageToDetect') >= 0"
          >
            <tr>
              <td colspan="2" class="detection-error">
                {{ errors.errors.imageToDetect[0] }}
              </td>
            </tr>
          </template>
        </template>

        <tr>
          <td class="apply-loader">
            <div class="prev-ind-wrapper">
              {{ lastIndicators.svalue }}
            </div>
          </td>
          <td class="apply-loader">
            <template v-if="photoMode">
              <uploader :after-read="photoSvalueTaken">
                <camera
                  :fill-color="appTheme === 'dark' ? '#ffffff' : '#1d1d1d'"
                />
              </uploader>
            </template>
            <template v-else>
              <itserve-input
                v-model.number="indications.svalue"
                type="numberFi"
                variant="small"
                name="svalue"
                class="apply-loader"
                :placeholder="
                  $t('regionalEnergy.sendIndications.putIndications')
                "
              />
            </template>
          </td>
        </tr>
      </table>
      <itserve-button
        type="primary"
        :value="$t('buttons.sendIndicationsRegionalEnergy')"
        class="itserve-button-wide mt-15 apply-loader"
        @click="verifyIndications"
      ></itserve-button>
      <simple-dialog
        v-model="showDialog"
        :title="dialogMessage"
        :success="dialogSuccessMessage"
      />
    </van-col>
    <div id="indications">
      <van-row v-if="showRate === true" class="payment-box">
        <div @click="runRate" class="white-color center mt-15 mb-15">
          {{ $t("settings.rate_now") }}
        </div>
        <van-col span="4"></van-col>
        <van-col span="16">
          <van-button
            @click="runRate"
            round
            size="large"
            class="mt-15"
            type="info"
          >
            {{ $t("settings.rate_now_button") }}
          </van-button>
        </van-col>
        <van-col span="4"></van-col>
      </van-row>
    </div>
    <template v-if="showVerifyDialog">
      <itserve-dialog
        :cancel-text="$t('contractVerification.cancelDate')"
        :show-cancel="true"
        :show-confirm="false"
        :dialog-title="$t('regionalEnergy.oblenergoVerifyNew')"
        @cancel="handleVerifyCancel"
      >
        <div class="main-page-account place-content-center">
          <div @click="verifyViber">
            <itserve-route
              :buttonName="$t('buttons.viber')"
              :params="$route.params"
              type="wide"
              class="nav-btn-verify mt-15-simple"
              :icon="true"
              btn-style="box"
            >
              <viber-icon />
            </itserve-route>
          </div>
          <div @click="verifyTelegram">
            <itserve-route
              :buttonName="$t('buttons.telegram')"
              :params="$route.params"
              type="wide"
              class="nav-btn-verify mt-15-simple fr"
              :icon="true"
              btn-style="box"
            >
              <telegram-icon size="85%" />
            </itserve-route>
          </div>
        </div>
      </itserve-dialog>
    </template>
    <simple-dialog
      v-model="showDialog2"
      :success="actionSuccessfully"
      :title="dialogMessage"
    />
    <simple-dialog
      v-model="showDialog3"
      :success="actionSuccessfully"
      :title="dialogMessage"
    />
  </div>
</template>

<script>
import indications from "@/mixins/regional-energy/indications";
import SimpleDialog from "@/components/content/SimpleDialog";
import Camera from "vue-material-design-icons/Camera";
import Uploader from "vant/lib/uploader/index";
import VanButton from "vant/lib/button/index";
import ItserveDialog from "@/components/content/ItserveDialog";
import ItserveRoute from "@/components/content/ItserveRoute";
import TelegramIcon from "@/components/icons/telegram-icon";
import ViberIcon from "@/components/icons/viber-icon";

export default {
  components: {
    SimpleDialog,
    Uploader,
    Camera,
    VanButton,
    ItserveDialog,
    ItserveRoute,
    TelegramIcon,
    ViberIcon,
  },
  mixins: [indications],
  computed: {
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
    showRate() {
      return this.$store.state.RegionalEnergy.showRate;
    },
    indicationPhone() {
      return this.$store.state.Settings.indicationPhone.phone;
    },
  },
  name: "SingleZoneStandart",
  data() {
    return {
      showDialog2: false,
      showDialog3: false,
      actionSuccessfully: false,
      showVerifyDialog: false,
    };
  },
  methods: {
    handleVerifyCancel() {
      this.showVerifyDialog = false;
    },
    runRate() {
      this.nativeSendEvent("url", "https://cutt.ly/NwEFP7CC");
    },

    verifyViber() {
      let telegramData = [this.$store.state.Auth.nativeData.uid];

      let base64 = btoa(JSON.stringify(telegramData));
      this.nativeSendEvent(
        "url",
        "viber://pa?chatURI=electricitybot&context=" + base64
      );
      this.showVerifyDialog = false;
    },

    verifyTelegram() {
      let telegramData = [this.$store.state.Auth.nativeData.uid];

      let base64 = btoa(JSON.stringify(telegramData));
      this.nativeSendEvent(
        "url",
        "https://t.me/ElectricityphoneBot?start=" + base64
      );
      this.showVerifyDialog = false;
    },

    async verifyIndications() {
      // await this.$store.dispatch(
      //   "Settings/getIndicationPhone",
      //   this.$store.state.Auth.nativeData.uid
      // );
      // if (this.$store.state.Settings.newIndicationPhone === null) {
      //   this.showVerifyDialog = true;
      // } else {
        await this.sendIndications();
      // }
    },
  },
  updated() {
    const el = this.$el.getElementsByClassName("payment-box")[0];

    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/uploader/index.css";
.prev-ind-wrapper {
  margin-top: 18px;
}
.send-indications-table {
  margin-top: 10px;
}
table {
  width: 100%;
}
.info-table th {
  text-align: left;
  padding-left: 24px;
  padding-top: 12px;
  font-size: 14px;
  color: #0b0b0b;
  font-weight: normal;
}
.info-table td {
  font-size: 16px;
  padding-right: 24px;
  text-align: right;
  color: #918dab;
}
.send-indications-table th {
  font-size: 16px;
  color: #2e73b4;
  padding-top: 3px;
  text-align: center;
}
.send-indications-table td {
  color: #0b0b0b;
  font-size: 16px;
  padding-top: 7px;
  width: 50%;
}
.indications-block tr th {
  padding-top: 10px;
}
.app-theme-dark .payment-box {
  background-color: #1d1d1d;
}
.app-theme-dark .white-color {
  color: #fff;
}
.pt15 {
  padding-top: 15px;
}
.center {
  text-align: center;
}
.verify-message {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
}
.main-page-account {
  padding-bottom: 45px;
}
</style>
