<template>
  <van-row justify="center" >
    <loader :show="showLoader" />
    <template>
      <div v-if="total">
        <router-link v-if="status === 0" :to="{ name: 'StripePayment' }">
          <van-notice-bar color="red"
                          left-icon="volume-o">
            {{ $t("notice-bar.balance") }}
          </van-notice-bar>
        </router-link>
        <div class="white-color center mt15">{{ account.city }} {{ account.streetType }} {{ account.streetName }}
          {{ account.building }}
        </div>
<!--        <div>-->
<!--          <van-row>-->
<!--            <van-col span="4"></van-col>-->
<!--            <van-col span="16">-->
<!--              <van-button-->
<!--                  @click="updateBills"-->
<!--                  style="margin-top: 10px; margin-bottom: 10px; border-radius: 8px"-->
<!--                  round-->
<!--                  size="small"-->
<!--                  type="info">-->
<!--                {{ $t('pay.actual') }}-->
<!--              </van-button>-->
<!--            </van-col>-->
<!--            <van-col span="4"></van-col>-->
<!--          </van-row>-->
<!--        </div>-->
<!--          <div class="white-color center mt15">-->
<!--            {{ $t('pay.no_more') }}-->
<!--          </div>-->
        <services
            :account="account"
            :total="total"
            :balances="balances"
            :show-balance="true"
        />
      </div>
    </template>
  </van-row>
</template>
<script>
import "vue-awesome/icons/money-bill-wave";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/spinner";
import "vue-awesome/icons/file-invoice-dollar";
import VanNoticeBar from "vant/lib/notice-bar/index";
// import VanButton from "vant/lib/button/index";
import Loader from "@/components/content/Loader";
import Services from "../../../components/helpers/Services";

export default {
  name: "PayAll",
  components: {
    Services,
    VanNoticeBar,
    // VanButton,
    Loader

  },
  created() {
    this.getBalancesData();
  },
  beforeRouteLeave(to, from, next) {
    if (window.Tawk_API) {
      window.Tawk_API.showWidget();
    }
    next();
  },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
    loadingMessage() {
      return this.$store.state.Api.loadingMessage;
    },
    total() {
      return this.$store.state.Auth.total;
    },
    balances() {
      return this.$store.state.Auth.balances;
    },
    showLoader() {
      return this.$store.state.Api.loading;
    },
    status() {
      console.log("USER STATUS: " + this.$store.state.Auth.status);
      return this.$store.state.Auth.status;
    }
  },
  methods: {
    async getBalancesData(){
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }

      // let cardType = "balances";
      // await this.$store.dispatch("Payment/getCardsList", cardType);
      await this.$store.dispatch(
          "Auth/getUserBalances",
          this.account
      );
    },
    async updateBills() {
      if(this.status === 0) {
        this.nativeSendEvent("preloadInterstitial");
      }
      await this.$store.dispatch(
          "Auth/updateUserBalances",
          this.account
      );
    },
  }
};
</script>

<style scoped>
.app-theme-dark .white-color {
  color: #fff;
}

.white-color{
  margin-top: 10px;
}

.center {
  text-align: center;
}

.van-icon-new {
  position: relative;
  display: inline-block;
  font: normal normal normal 14px/1 vant-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased
}

.van-icon-new:before {
  display: inline-block
}
</style>
