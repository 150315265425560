import cloneDeep from "lodash.clonedeep";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
export default {
  components: { ItserveInput, ItserveButton },
  data() {
    return {
      showDialog: false,
      tries: 0,
      dialogMessage: "",
      dialogSuccessMessage: false,
      modeMessages: [
        this.$t("regionalEnergy.sendIndications.successfullySent"),
        this.$t("regionalEnergy.sendIndications.partiallySuccess"),
        this.$t("regionalEnergy.sendIndications.errorDataCreation"),
        this.$t("regionalEnergy.sendIndications.unknownError"),
        this.$t("regionalEnergy.sendIndications.indicationsTooBig"),
        this.$t("regionalEnergy.sendIndications.indicationsTooSmall"),
        this.$t("regionalEnergy.sendIndications.alreadySentToday"),
        this.$t("regionalEnergy.sendIndications.sendLimitation"),
      ],
      indications: {
        ls: this.$route.params.account,
        svalue: null,
        svalue1: null,
        svalue2: null,
        svalue3: null,
      },
    };
  },
  computed: {
    photoMode() {
      return this.$store.state.RegionalEnergy.sendPhoto;
    },
    lastIndicators() {
      return this.$store.state.RegionalEnergy.lastIndicators;
    },
    errors() {
      return this.$store.state.Api.errors;
    },
    errorMessage() {
      return this.$store.state.Api.errors
        ? this.$store.state.Api.errors.message
        : null;
    },
  },
  methods: {
    async photoSvalueTaken(file) {
      const res = await this.$store.dispatch(
        "RegionalEnergy/detectIndications",
        file
      );
      this.indications.svalue = res.result;
      this.$store.commit("RegionalEnergy/SET_PHOTO_MODE", false);
    },
    /**
     * If counter have 2 zones - in this case as svalue should be equal svalue2+svalue3
     * if counter have 3 zones  - in this case we should send inside svalue summ of all other svalues (svalue1+svalue2+svalue3)
     * before send - data should be prepared for Oblenergo server, if counter doesn't have some zones values should be equal 0
     * to not remake our input component (such as this feature will be used signe time) we going to clone variable and change
     * null values to 0 values before send data, clone need to prevent changing values what left inside form
     * once data was sent - show dialog message with validation error from Oblenergo or with Success message
     * @returns {Promise<void>}
     */
    async sendIndications() {
      if (this.flashlightEnabled) {
        await this.$store.dispatch("Flashlight/disableFlashlight");
      }
      if (this.lastIndicators.nzone === 2) {
        this.indications.svalue =
          this.indications.svalue2 + this.indications.svalue3;
      }
      if (this.lastIndicators.nzone === 3) {
        this.indications.svalue =
          this.indications.svalue1 +
          this.indications.svalue2 +
          this.indications.svalue3;
      }
      let indicators = cloneDeep(this.indications);
      for (let objKey in indicators) {
        if (indicators[objKey] === null) {
          indicators[objKey] = 0;
        }
      }
      indicators['uid'] = this.$store.state.Auth.nativeData.uid;

      let res = await this.$store.dispatch(
        "RegionalEnergy/sendIndications",
        indicators
      );
      console.log("INDICATION RESPONSE" + JSON.stringify(res));
      if (Object.keys(res).indexOf("mode") >= 0) {
        this.showDialog = true;
        if (res) {
          this.dialogMessage = this.modeMessages[res.mode - 1];
          if (res.mode === 1 || res.mode === 2) {
            this.$store.commit("RegionalEnergy/SHOW_RATE", true);
            console.log("INDICATION NO ERROR");
            this.dialogSuccessMessage = true;
          }
          if (res.mode === 5) {
            this.$store.commit("RegionalEnergy/SHOW_RATE", true);

            this.tries++;
            // if (this.tries === 2) {
            //   await this.$router.push({
            //     name: "SendIndicationsPhoto",
            //     params: { account: this.$route.params.account },
            //   });
            // }
          }
        } else {
          this.dialogMessage = this.errorMessage;
          console.log("INDICATION ERROR");
          this.$store.commit("RegionalEnergy/SHOW_RATE", true);
          this.dialogSuccessMessage = false;
        }
      }
    },
  },
};
