<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center round-shadow-btn verify-flex" span="24">
      <van-row>
        <van-col span="24" class="date-block apply-loader mt-25">
          {{ $t("counterVerification.counterPlaceholder") }}
        </van-col>
      </van-row>
      <itserve-input
        v-model="verification.mtrsn"
        name="mtrsn"
        type="number"
        inputmode="numeric"
        class="apply-loader verification-input"
      />
      <template v-if="!getPhone || !email">
        <div class="place-content-center">
          <info-message class="settings-message verify-message">
            {{ $t("regionalEnergy.oblenergoVerify") }}
          </info-message>
        </div>
      </template>
      <template v-if="!getPhone">
        <div class="place-content-center">
          <van-form>
            <itserve-input
                v-model="newPhone"
                inputmode="tel"
                type="text"
                class="apply-loader fl-upper"
                :placeholder="$t('settings.phone')"
                name="name"
            />
          </van-form>
        </div>
      </template>
      <template v-if="!checkEmail">
        <div class="place-content-center">
          <van-form>
            <itserve-input
                v-model="email"
                type="email"
                class="apply-loader"
                :placeholder="$t('settings.email')"
                name="email"
            />
          </van-form>
        </div>
      </template>
    </van-col>
    <van-col span="24" class="itserve-center">
      <itserve-button
        type="primary"
        :value="$t('verification.verifyButton')"
        class="itserve-button-wide verification-button mt-15 apply-loader"
        @click="verifiCheck"
      ></itserve-button>
    </van-col>
    <simple-dialog
        v-model="showDialog"
        :title="dialogMessage"
        :success="success"
        @change="goToAccount"
    />
    <simple-dialog
        v-model="showDialog2"
        :success="actionSuccessfully"
        :title="dialogMessage"
    />
    <simple-dialog
        v-model="showDialog3"
        :success="actionSuccessfully"
        :title="dialogMessage"
    />
  </van-row>
</template>

<script>
import ItserveButton from "@/components/forms/ItserveButton";
import ItserveInput from "@/components/forms/ItserveInput";
import InfoMessage from "@/components/content/InfoMessage";
import sendVerificationRequest from "@/mixins/verification/sendVerificationRequest";
import "vue-awesome/icons/check";
import SimpleDialog from "@/components/content/SimpleDialog";
import Form from "vant/lib/form/index";
export default {
  name: "CounterVerification",
  components: { SimpleDialog, ItserveButton, ItserveInput, VanForm: Form, InfoMessage },
  computed: {
    formErrors() {
      return this.$store.state.Api.errors;
    },
    email: {
      get() {
        return this.$store.state.Settings.settings.email;
      },
      set(email) {
        this.$store.commit("Settings/UPDATE_EMAIL", email);
      },
    },
    getPhone() {
      return this.$store.state.Auth.phoneNumber;
    },
    newPhone: {
      get() {
        return this.$store.state.Settings.newPhone;
      },
      set(newPhone) {
        this.$store.commit("Settings/UPDATE_PHONE", newPhone);
        this.dialogMessage = this.$t("settings.successMessage");
      },
    },
  },
  mixins: [sendVerificationRequest],
  data() {
    return {
      actionSuccessfully: false,
      showDialog2: false,
      showDialog3: false,
      checkEmail: this.$store.state.Settings.settings.email,
      verification: {
        ls: this.$route.params.account,
        mtrsn: null,
        type: "counter",
      },
    };
  },
  created() {
    this.getPageData();
  },
  methods: {
    async getPageData() {
      await this.$store.dispatch("Settings/getSettings");
      await this.$store.dispatch("Settings/getPhone");
    },
    verifiCheck() {
      this.checkEmail = this.email;
      // if (!this.getPhone || this.checkEmail === null) {
      //   this.showDialog3 = true;
      //   this.dialogMessage = this.$t("settings.emptyField");
      //   this.actionSuccessfully = false;
      // }
      console.log(this.$store.state.Settings.newPhone);
      // if (
      //     this.$store.state.Settings.newPhone.length !== 13 ||
      //     this.$store.state.Settings.newPhone.substr(0, 1) !== "+"
      // ) {
      //   this.showDialog3 = true;
      //   this.dialogMessage = this.$t("settings.falseMessage");
      //   this.actionSuccessfully = false;
      // } else {
      //   this.$store.dispatch("Settings/storePhone").then((res) => {
      //     if (res) {
      //       this.$store.state.Auth.phoneNumber = this.newPhone;
      //     } else {
      //       this.showDialog2 = true;
      //       this.dialogMessage = this.$t("settings.error");
      //     }
      //   });
      // }

      // if (this.$store.state.Auth.phoneNumber && this.checkEmail !== null) {
      console.log("WTF!!!!!")
      this.$store.dispatch("Settings/storeSettings")
      this.verificate();
      // }
    },
  },

};
</script>

<style scoped>
@import "../../../../../node_modules/vant/lib/picker/index.css";
@import "../../../../../node_modules/vant/lib/cell/index.css";
@import "../../../../../node_modules/vant/lib/popup/index.css";
@import "../../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../../node_modules/vant/lib/overlay/index.css";

.absolute-title {
  position: absolute;
  z-index: 9999;
  width: 100%;
  top: 44px;
}

.input-wrapper {
  overflow: hidden;
}

.changed-buttons .van-picker__toolbar {
    margin-bottom: 64px;
  }

.van-picker__confirm {
  background-color: #2e73b4;
  color: #fff;
  margin-top: 10px;
  margin-right: 15px;
  height: 30px;
  margin-left: 5px;
}

.van-picker__cancel {
  background-color: #2e73b4;
  color: #fff;
  margin-top: 10px;
  margin-left: 15px;
  height: 30px;
  margin-right: 5px;
}
.verify-message {
  margin-top: 0!important;
  margin-bottom: 0!important;
}
.verify-flex{
  display: flex;
  display: -webkit-box;
  width: 100%;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-shrink: 0;
  flex-direction: column;
}
</style>
