<template>
  <van-dialog
    :cancel-button-text="cancelText"
    :confirm-button-text="confirmText"
    v-model="show"
    :title="dialogTitle"
    :show-cancel-button="showCancel"
    :show-confirm-button="showConfirm"
    confirm-button-color="#1989fa"
    cancel-button-color="#1989fa"
    @cancel="cancel"
    @confirm="confirm"
  >
    <slot class="dialog-content"></slot>
  </van-dialog>
</template>
<script>
import Dialog from "vant/lib/dialog/index";

export default {
  name: "ItserveDialog",
  data() {
    return {
      show: true,
    };
  },
  props: {
    cancelText: String,
    confirmText: String,
    dialogTitle: String,
    showCancel: Boolean,
    showConfirm: Boolean
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
