<template>
  <van-row justify="center" class="page-wrapper prices-page">
    <van-col
      span="24"
      class="itserve-center place-content-center flex-display-fix"
    >
<!--      <info-message class="prices-message round-shadow p-25 pb-25 apply-loader">-->
<!--        <h5 class="prices-title itserve-center">{{ $t("prices.upTo100") }}</h5>-->
<!--        <van-row class="prices-block">-->
<!--          <van-col span="12" class="price-text-left">-->
<!--            {{ $t("prices.withoutVat") }}-->
<!--          </van-col>-->
<!--          <van-col span="12" class="price-text-right"> 1.20 UAH </van-col>-->
<!--          <van-col span="12" class="price-text-left">-->
<!--            {{ $t("prices.vat") }}-->
<!--          </van-col>-->
<!--          <van-col span="12" class="price-text-right"> 0.24 UAH </van-col>-->
<!--          <van-col span="12" class="price-text-left">-->
<!--            {{ $t("prices.withVat") }}-->
<!--          </van-col>-->
<!--          <van-col span="12" class="price-text-right"> 1.44 UAH </van-col>-->
<!--        </van-row>-->
<!--      </info-message>-->
      <info-message class="prices-message round-shadow p-25 pb-25 apply-loader">
        <h5 class="prices-title itserve-center">
          {{ $t("prices.newTariff") }}
        </h5>
        <van-row>
          <van-col span="12" class="price-text-left">
            {{ $t("prices.withoutVat") }}
          </van-col>
          <van-col span="12" class="price-text-right"> 2.2 UAH </van-col>
          <van-col span="12" class="price-text-left">
            {{ $t("prices.vat") }}
          </van-col>
          <van-col span="12" class="price-text-right"> 0.44 UAH </van-col>
          <van-col span="12" class="price-text-left">
            {{ $t("prices.withVat") }}
          </van-col>
          <van-col span="12" class="price-text-right"> 2.64 UAH </van-col>
        </van-row>
      </info-message>
      <template v-for="(singleCoef, index) in coef">
        <info-message
          class="prices-message round-shadow p-25 pb-25 apply-loader"
          :key="`coef=${index}`"
        >
          <van-row>
            <van-col span="24">
              <van-cell
                :title="$t('prices.counterType')"
                :value="singleCoef.zone"
              />
              <van-cell :title="$t('prices.time')" :value="singleCoef.time" />
              <van-cell title="" :value="singleCoef.price" />
            </van-col>
          </van-row>
        </info-message>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
import Cell from "vant/lib/cell/index";
export default {
  name: "Prices",
  components: { InfoMessage, VanCell: Cell },
  data() {
    return {
      coef: [
        {
          time: "23.00 — 7.00",
          price: this.$t("prices.halfPrice"),
          zone: this.$t("prices.twoZoneType"),
        },
        {
          time: "7.00 — 23.00",
          price: this.$t("prices.fullPrice"),
          zone: this.$t("prices.twoZoneType"),
        },
        {
          time: "23.00 — 7.00",
          price: this.$t("prices.fourPrice"),
          zone: this.$t("prices.threeZoneType"),
        },
        {
          time: "7.00 — 8.00",
          price: this.$t("prices.fullPrice"),
          zone: this.$t("prices.threeZoneType"),
        },
        {
          time: "8.00 — 11.00",
          price: this.$t("prices.moreThanOnePrice"),
          zone: this.$t("prices.threeZoneType"),
        },
        {
          time: "11.00 — 20.00",
          price: this.$t("prices.fullPrice"),
          zone: this.$t("prices.threeZoneType"),
        },
        {
          time: "20.00 — 22.00",
          price: this.$t("prices.moreThanOnePrice"),
          zone: this.$t("prices.threeZoneType"),
        },
        {
          time: "22.00 — 23.00",
          price: this.$t("prices.fullPrice"),
          zone: this.$t("prices.threeZoneType"),
        },
      ],
    };
  },
};
</script>
<style>
.prices-page .info-content {
  width: 100%;
  padding: 0;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
.prices-block div {
  position: relative;
  float: left;
  box-sizing: border-box;
}
.prices-message {
  padding-top: 15px;
  padding-left: 11px;
}
.prices-title {
  color: #2e73b4;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.price-text-left {
  text-align: left;

  padding-top: 10px;
  padding-bottom: 5px;
  color: #0b0b0b;
  font-size: 16px;
}
.price-text-right {
  text-align: right;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #918dab;
}
</style>
