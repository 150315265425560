<template>
  <van-row class="page-wrapper">
    <template>
      <div class="support-off itserve-center">
        {{ $t("calculation.calculationHeader") }}
      </div>
      <van-col v-if="meterage === 1" class="flex-display-fix" span="24">
        <h3 class="tafiff-zones apply-loader itserve-center calculation-text">
          {{
            $t("calculation.zone1Meterage")
          }}
        </h3>
        <div>
          <h5 class="tafiff-zones calculation-text">
            {{ $t("calculation.tariff") }}: {{ highTarrif }} {{ $t("calculation.value")}}</h5>
        </div>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffOnePhaseData"
            disabled
        ></v-text-field>
        <van-col v-if="totalPayment > 0" class="flex-display-fix" span="24">
          <h5 class="tafiff-zones calculation-text" style="font-size: 20px;">
            {{ $t("calculation.totalPayment")  }} {{ totalPayment }} {{ $t("calculation.value")}}
          </h5>
        </van-col>
        <div class="itserve-center">
          <itserve-buttonw
              type="primary"
              :value="$t('calculation.calculate')"
              class="itserve-button-wide mt-15 apply-loader"
              @click="makeCalculations"
          ></itserve-buttonw>
        </div>
      </van-col>
      <van-col v-else-if="meterage === 2" class="flex-display-fix" span="24">
        <h3 class="tafiff-zones itserve-center calculation-text">
          {{
            $t("calculation.zone2Meterage")
          }}
        </h3>
        <div>
          <h5 class="tafiff-zones calculation-text">
            <br />{{ $t("calculation.tariff") }}: {{ highTarrif }} {{ $t("calculation.value")}}
            <br />{{ $t("calculation.nightZone") }}: 0.5
            <br />{{ $t("calculation.dayZone") }}: 1</h5>
        </div>
        <h5 class="tafiff-zones itserve-center calculation-text">
          23:00 - 7:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffOnePhaseData"
            disabled
        ></v-text-field>
        <h5 class="tafiff-zones itserve-center calculation-text">
          07:00 - 23:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffTwoPhaseData"
            disabled
        ></v-text-field>
        <van-col v-if="totalPayment > 0" class="flex-display-fix" span="24">
          <h5 class="tafiff-zones calculation-text" style="font-size: 20px;">
            {{ $t("calculation.totalPayment")  }} {{ totalPayment }} {{ $t("calculation.value")}}
          </h5>
        </van-col>
        <div class="itserve-center">
          <itserve-button
              type="primary"
              :value="$t('calculation.calculate')"
              class="itserve-button-wide mt-15 apply-loader"
              @click="makeCalculations"
          ></itserve-button>
        </div>
      </van-col>
      <van-col v-else-if="meterage === 3" class="flex-display-fix" span="24">
        <h3 class="tafiff-zones itserve-center calculation-text">
          {{
            $t("calculation.zone3Meterage")
          }}
        </h3>
        <div>
          <h5 class="tafiff-zones calculation-text"><br />
            {{ $t("calculation.tariff") }}: {{ highTarrif }} {{ $t("calculation.value")}}<br />{{ $t("calculation.nightZone") }}: 0.4<br />
            {{ $t("calculation.lowPickZone") }}: 1.5<br />
            {{ $t("calculation.pickZone") }}: 1</h5>
        </div>
        <h5 class="tafiff-zones itserve-center calculation-text">
          08:00 - 11:00, 20:00 - 22:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentOnePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffOnePhaseData"
            disabled
        ></v-text-field>
        <h5 class="tafiff-zones itserve-center calculation-text">
          7:00 - 8:00, 11:00 - 20:00, 22:00 - 23:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentTwoPhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffTwoPhaseData"
            disabled
        ></v-text-field>
        <h5 class="tafiff-zones itserve-center calculation-text">
          07:00 - 23:00
        </h5>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.previousKwt")
          }}
        </h5>
        <v-text-field
            v-model="previousThreePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.currentKwt")
          }}
        </h5>
        <v-text-field
            v-model="currentThreePhaseData"
            type="number"
        ></v-text-field>
        <h5 class="tafiff-zones calculation-text">
          {{
            $t("calculation.differenceKwt")
          }}
        </h5>
        <v-text-field
            v-model="diffThreePhaseData"
            disabled
        ></v-text-field>
        <van-col v-if="totalPayment > 0" class="flex-display-fix" span="24">
          <h5 class="tafiff-zones calculation-text" style="font-size: 20px;">
            {{ $t("calculation.totalPayment")  }} {{ totalPayment }} {{ $t("calculation.value")}}
          </h5>
        </van-col>
        <div class="itserve-center">
          <itserve-button
              type="primary"
              :value="$t('calculation.calculate')"
              class="itserve-button-wide mt-15 apply-loader"
              @click="makeCalculations"
          ></itserve-button>
        </div>
      </van-col>
    </template>

  </van-row>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
export default {
  components: {
    ItserveButton
  },
  name: "CalculationPage",
  data() {
    return {
      meterage: this.$route.params.meterage,
      previousOnePhaseData: 0,
      currentOnePhaseData: 0,
      diffOnePhaseData: 0,
      previousTwoPhaseData: 0,
      currentTwoPhaseData: 0,
      diffTwoPhaseData: 0,
      previousThreePhaseData: 0,
      currentThreePhaseData: 0,
      diffThreePhaseData: 0,
      totalPayment: 0,
      lowTarrif: process.env.VUE_APP_LOW_TARIFS,
      highTarrif: process.env.VUE_APP_HIGH_TARIFS,
    };
  },
  computed: {

  },
  watch: {
    previousOnePhaseData(){
      this.diffOnePhaseData = this.currentOnePhaseData - this.previousOnePhaseData;
    },
    currentOnePhaseData(){
      this.diffOnePhaseData = this.currentOnePhaseData - this.previousOnePhaseData;
    },
    previousTwoPhaseData(){
      this.diffTwoPhaseData = this.currentTwoPhaseData - this.previousTwoPhaseData;
    },
    currentTwoPhaseData(){
      this.diffTwoPhaseData = this.currentTwoPhaseData - this.previousTwoPhaseData;
    },
    previousThreePhaseData(){
      this.diffThreePhaseData = this.currentThreePhaseData - this.previousThreePhaseData;
    },
    currentThreePhaseData(){
      this.diffThreePhaseData = this.currentThreePhaseData - this.previousThreePhaseData;
    },
  },
  created() {
  },
  destroyed() { },
  methods: {
    makeCalculations(){
      let tariff;

        tariff = process.env.VUE_APP_HIGH_TARIFS;

      if(this.meterage === 1){
        this.totalPayment = this.diffOnePhaseData * tariff;
      }
      else if(this.meterage === 2){
        this.totalPayment = (this.diffOnePhaseData * tariff * 0.5) + (this.diffTwoPhaseData * tariff);
      }
      else if(this.meterage === 3){
        this.totalPayment = (this.diffOnePhaseData * tariff * 1.5) + (this.diffTwoPhaseData * tariff) + (this.diffThreePhaseData * tariff * 0.4);
      }
      this.totalPayment = parseFloat(this.totalPayment);
      this.totalPayment = this.totalPayment.toFixed(2);
    }
  },
};
</script>
<style>
.v-text-field input {
  text-align: left;
  text-align: -moz-left;
  text-align: -webkit-left;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 0 10px 0 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
}

.count-zones {
  display: inline-block;
  padding: 5px 28px 0 28px;
}
.tafiff-zones {
  display: inline-block;
  padding: 0 28px 0 28px;
}
.support-off {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
