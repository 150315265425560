<template>
  <div class="payment-page">
    <div class="payment-form">
        <van-row v-for="(desc, index) in description" v-bind:key="index" justify="center">
          <van-col span="24">
            <p class="desc-label">{{ desc }}</p>
          </van-col>
        </van-row>
      <div id="card-element"></div>
      <button @click="submitPayment">{{ $t('pay.pay') }} {{ total }} {{ $t("currency.uah") }}</button>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Dialog from "vant/lib/dialog/index";

export default {
  name: "StripePaymentPage",
  data() {
    return {
      stripe: null,
      elements: null,
      cardElement: null,
      paymentError: "",
      clientSecret: null,
      paymentSum: null,
      description: null,
    };
  },
  created() {
    this.initializeStripe();
  },
  computed: {
    total() {
      return this.$store.state.Auth.total;
    },
    balances() {
      return this.$store.state.Payment.paymentData.balances
    }
  },
  methods: {
    async initializeStripe() {
      const publicKey = "pk_live_51NuWekG3LqK0WGaa1bjz9FTtwpYGvgIrY6pEFtGnq5n58DqUgv18HFutoKZa1KFz03Gef3RWjBItI0vfHFxce0Jh00DZJq8Env";
      //const publicKey= "pk_test_0WLIqn1pnutsYPljf5LNE1hk";
      try {
        const stripe = await loadStripe(
            publicKey
        );
        let paymentData = {
          paymentData: this.$store.state.Payment.paymentData,
          user_id: this.$store.state.Auth.user_id,
          ls: this.$store.state.Auth.account.accountNumber,
          total: this.$store.state.Auth.total
        };
        const response = await this.$store.dispatch(
            "Payment/createPaymentIntentData",
            paymentData
        );
        console.log("response.clientSecret ", JSON.stringify(response.clientSecret));

        const clientSecret = response.clientSecret.client_secret;
        const description = response.clientSecret.metadata;
        const elements = stripe.elements({ clientSecret,
          appearance: {
            rules: {
              '.Label': {
                fontSize: '0'
              }
            }
          }
        });
        const paymentElement = elements.create("payment", {
          fields: {
            billingDetails: {
              address: {
                country: 'never',
                postalCode: 'never'
              }
            }
          }
        });
        paymentElement.on('loaderror', function(event) {
          console.log(JSON.stringify(event));
        });
        paymentElement.mount("#card-element");

        this.clientSecret = clientSecret;
        this.cardElement = paymentElement;
        this.stripe = stripe;
        this.elements = elements;
        this.description = description;
        console.log("this.clientSecret ", JSON.stringify(this.clientSecret));
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
    },
    async submitPayment() {
      try {
        if (!this.stripe || !this.elements || !this.cardElement) {
          throw new Error("Stripe elements not initialized.");
        }

        await this.elements.submit();

        let return_url = "https://view-kharkiv-energo.billing.city/payment/success";
        // let return_url = "http://localhost:8080/payment/success";

        this.stripe
            .confirmPayment({
              redirect: "if_required",
              confirmParams: {
                return_url: return_url,
                payment_method_data: {
                  billing_details: {
                    address: {
                      country: 'UA',
                      postal_code: '61000'
                    }
                  }
                }
              },
              payment_method: {
                card: this.cardElement,
              },
              clientSecret: this.clientSecret,
              elements: this.elements
            })
            .then(({ paymentIntent, error }) => {
              if (error) {
                console.log("Payment error:", error);
                this.paymentError = error.message;
                Dialog.alert({
                  message: error.message,
                  confirmButtonText: "Ok",
                });
              } else {
                paymentIntent['paymentData'] = this.$store.state.Payment.paymentData;
                paymentIntent['user_id'] = this.$store.state.Auth.user_id;
                paymentIntent['ls'] = this.$store.state.Auth.account.accountNumber;
                this.$store.dispatch(
                    "Payment/setSuccessClientPayment",
                    paymentIntent
                );
                console.log("Payment successful:", JSON.stringify(paymentIntent));
                this.$router.push({ name: "SuccessfullyPaid" });

              }
            })
            .catch((error) => {
              console.error("Error processing payment:", error);
              this.paymentError = error.message;
              Dialog.alert({
                message: error.message,
                confirmButtonText: "Ok",
              });
            });
      } catch (error) {
        console.error("Error processing payment:", error);
        this.paymentError = error.message;
      }
    },
  },
};
</script>

<style scoped>
.payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.payment-header {
  margin-bottom: 20px;
  font-size: 24px;
}
.app-theme-dark .payment-header {
  color: #ffffff;
}

.payment-form {
  width: 80%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-theme-dark .payment-form {
  background-color: #ffffff;
}

.payment-form button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-error {
  margin-top: 20px;
  color: red;
}
.desc-label {
  font-size: 14px;
  color: #000000;
}
</style>
