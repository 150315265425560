<template>
  <van-row justify="center" class="page-wrapper payment-page">

    <template>
      <van-col
          span="24"
          class="itserve-center bottom-content-grow flex-display-fix"
      >
        <form method="post" @submit="runPayment" action="https://www.portmone.com.ua/gateway/">
          <div class="itserve-center">
            <input type="hidden" name="bodyRequest" :value='JSON.stringify({
                 "paymentTypes": {
                      "card": "Y",
                      "gpay": googlePay,
                      "applepay": applePay
                  },
                  "priorityPaymentTypes": {
                      "card": "1",
                      "gpay": "2",
                      "applepay" :"0"
                  },
                  "payee": {
                   "payeeId": "23364",
                  },
                  "payer": {
                      "emailAddress": "",
                      "lang": this.$store.state.Auth.nativeData.platform
                  },
                  "order": {
                    "description": this.$store.state.Auth.account.accountNumber,
                    "shopOrderNumber": this.$store.state.Payment.order_id,
                    "successUrl": "https://kharkiv2.billing.city/api/enterprises/v1/payment/portmone/success-payment/" + this.$store.state.Payment.order_id,
                    "failureUrl": "https://kharkiv2.billing.city/api/enterprises/v1/payment/portmone/failed-payment",
                    "billAmount": this.$store.state.Payment.paymentData.amount,
                    "billCurrency": "UAH",
                    "attribute1": this.$store.state.Auth.account.accountNumber
                },
                  "style": {
                      "type": "light",
                      "bcMain": "#fff",
                      "backgroundColorButtons": "#0f51a6",
                      "colorTextAndIcons": "#0f51a6"
                  },
})' />
            <input type="hidden" name="typeRequest" value='json' />
            <itserve-button
                type="submit"
                :value="$t('pay.pay') + ' ' +  this.$store.state.Payment.paymentData.amount + ' ' +  $t('currency.uah')"
                class="mt-15 apply-loader"
            />
          </div>
        </form>

        <simple-dialog
            v-model="showDialog"
            :success="true"
            :title="this.$t('pay.fees')"
            @change="runPayment"
        />
      </van-col>
    </template>

  </van-row>
</template>
<script>
// import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import "vue-awesome/icons/money-bill-wave";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/file-invoice-dollar";
// import Account from "../../../components/helpers/Account";
// import ItserveRoute from "@/components/content/ItserveRoute";
// import PricesIcon from "@/components/icons/prices-icon";
import SimpleDialog from "@/components/content/SimpleDialog";
// import Badge from "vant/lib/badge/index";
export default {
  name: "PayAmount",
  components: {
    SimpleDialog,
    // PricesIcon,
    // ItserveRoute,
    ItserveButton,
    // ItserveInput,
    // VanBadge: Badge,
  },
  data() {
    return {
      billId: this.$route.params.bill,
      showDialog: false,
      amount: this.$route.params.amount,
      bill: [],
      credentials: "",
      portmonePay: 0,
      googlePay: "",
      applePay: ""
    };
  },
  computed: {},
  watch: {},
  created() {

    this.getCredentials()
  },
  methods: {
    showNotification() {
      if (this.cards.length) {
        this.showDialog = true;
      } else {
        this.runPayment();
      }
    },
    getCredentials(){
      if (this.$store.state.Auth.nativeData.platform === 'ios') {
        this.googlePay = 'N';
        this.applePay = 'Y';
      } else {
        this.googlePay = 'Y';
        this.applePay = 'N';
      }
      this.$store.dispatch("Payment/getCredentials");
    },
    runPayment() {
      this.portmonePay = 1;
      //await this.$store.dispatch("Payment/getPortmonePayment", this.bill);
    },
  },
};
</script>
<style>
.pay-info-message .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";

.button-badge {
  margin-bottom: 15px;
  max-width: 337px;
  display: inline-block;
  width: 100%;
  text-align: right;
  position: relative;
}

.button-badge .van-badge {
  padding: 5px 10px;
  font-size: 14px;
  color: #0b0b0b;
  border-radius: 4px;
}

.badge-triangle {
  width: 10px;
  height: 10px;
  background-color: #f6f6f6;
  position: absolute;
  right: 40px;
  transform: rotate(45deg);
  top: 23px;
}
.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: calc(100vh - 120px);
}
.iframe-container iframe {
  height: calc(100vh - 120px);
  min-width: 100%;
  width: 100%;
}
.payment-page {
  width: 100%;
  flex-grow: 1;
  border: none !important;
}
</style>
